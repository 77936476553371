const data = [
    {
        "text": "1 INT. SAFE HOUSE - OUTER CORRIDOR - MORNING 1",
        "page": 4,
        "lines": [
            {
                "text": "A CLEANING WOMAN pushes her trolley down the hall. She",
                "page": 4,
                "x0": 108,
                "x1": 493.17840000000024,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "arrives at the INTERNATIONAL SUITE door. She knocks. No",
                "page": 4,
                "x0": 108,
                "x1": 514.1984,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "reply. She swats away a fly. She keys her way inside.",
                "page": 4,
                "x0": 108,
                "x1": 493.17840000000024,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            }
        ],
        "scene_number": "1",
        "int_ext": "INT.",
        "location": "SAFE HOUSE - OUTER CORRIDOR",
        "time": "MORNING"
    },
    {
        "text": "2 INT. SAFE HOUSE - MORNING 2",
        "page": 4,
        "lines": [
            {
                "text": "The Cleaning Woman enters, the lights are low. A shape of",
                "page": 4,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "SPROULE in the far background that she’s not aware of. She",
                "page": 4,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "closes the door behind her and attends to a room leading off,",
                "page": 4,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "where she leaves her trolley, out of which she takes a small",
                "page": 4,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "basket of cleaning utensils. Again she swats at flies. She",
                "page": 4,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "chooses an AIR FRESHENER from the basket and sprays the air.",
                "page": 4,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "Continues up the stairs. She suddenly drops her basket as",
                "page": 4,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "she SEES:",
                "page": 4,
                "x0": 108,
                "x1": 171.19679999999997,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "CLOSE ON:",
                "page": 4,
                "x0": 108,
                "x1": 171.19679999999997,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "SPROULE - where he’s slid down against the door jam that",
                "page": 4,
                "x0": 108,
                "x1": 500.1780000000002,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "opens into the MAIN ROOM.",
                "page": 4,
                "x0": 108,
                "x1": 283.1903999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "Flies buzz around him.",
                "page": 4,
                "x0": 108,
                "x1": 262.19159999999994,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "The Cleaning Woman creeps a little closer as if expecting him",
                "page": 4,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "to awaken, then staggers back, covers her nose and FLEES.",
                "page": 4,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 444.592,
                "y1": 456.592
            }
        ],
        "scene_number": "2",
        "int_ext": "INT.",
        "location": "SAFE HOUSE",
        "time": "MORNING"
    },
    {
        "text": "3 INT. PRC BUILDING - FOYER - DAY 3",
        "page": 4,
        "lines": [
            {
                "text": "AL has a spring in his step, a jaunty stride and a FLOWER in",
                "page": 4,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "his lapel as he heads for the elevators.",
                "page": 4,
                "x0": 108,
                "x1": 388.2,
                "y0": 516.592,
                "y1": 528.592
            }
        ],
        "scene_number": "3",
        "int_ext": "INT.",
        "location": "PRC BUILDING - FOYER",
        "time": "DAY"
    },
    {
        "text": "4 INT. AL’S OFFICE - DAY 4",
        "page": 4,
        "lines": [
            {
                "text": "Al’s good mood continues as he picks up a wine glass with",
                "page": 4,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "lipstick traces and an empty bottle and returns them to the",
                "page": 4,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "bar. He finds a SCARF tucked into the cushions on the couch.",
                "page": 4,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "It’s Lily’s. He smiles to himself, and tucks it in his",
                "page": 4,
                "x0": 108,
                "x1": 493.17840000000024,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "jacket pocket. His office phone RINGS and he goes to answer",
                "page": 4,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "it.",
                "page": 4,
                "x0": 108,
                "x1": 129.1992,
                "y0": 636.592,
                "y1": 648.592
            }
        ],
        "scene_number": "4",
        "int_ext": "INT.",
        "location": "AL’S OFFICE",
        "time": "DAY"
    },
    {
        "text": "5 INT. AL’S INNER OFFICE - DAY 5",
        "page": 4,
        "lines": [
            {
                "text": "We see Al answer his phone.",
                "page": 4,
                "x0": 108,
                "x1": 297.18959999999987,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 4,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 2.",
                "page": 5,
                "x0": 90,
                "x1": 522.1996,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "5 CONTINUED: 5",
                "page": 5,
                "x0": 54,
                "x1": 526.2,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "AL",
                "page": 5,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Hello. Yes. Yes, of course I know",
                "page": 5,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "him. Who is this?",
                "page": 5,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "He sits slowly in his chair, ashen. Sweat POPS on his lip.",
                "page": 5,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "AL (CONT’D)",
                "page": 5,
                "x0": 252,
                "x1": 329.19599999999997,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "When was this? Well, when was he",
                "page": 5,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "discovered? I see. Are you there",
                "page": 5,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "now? I’d like very much to see",
                "page": 5,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "things as you found them. Please",
                "page": 5,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "don’t let anyone disturb anything.",
                "page": 5,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "Has any media been informed?",
                "page": 5,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "Alright, good, please hold off",
                "page": 5,
                "x0": 180,
                "x1": 390.1911999999999,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "until I’ve had a look.",
                "page": 5,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            }
        ],
        "scene_number": "5",
        "int_ext": "INT.",
        "location": "AL’S INNER OFFICE",
        "time": "DAY"
    },
    {
        "text": "6 INT. HOTEL ROOM - DAY 6",
        "page": 5,
        "lines": [
            {
                "text": "CHARLIE CORMACK is in shirtsleeves reading the paper and",
                "page": 5,
                "x0": 108,
                "x1": 500.1780000000002,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "taking breakfast in his room. Briefcase and work on the bed.",
                "page": 5,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "A leisurely moment with room service and coffee on the public",
                "page": 5,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "purse before the day intervenes. His phone BUZZES. He picks",
                "page": 5,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "it up casually and reads the text. He rises quickly to his",
                "page": 5,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "feet, alarmed.",
                "page": 5,
                "x0": 108,
                "x1": 206.19479999999993,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "CORMACK",
                "page": 5,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "Good Christ. Goddamn it. You’ve",
                "page": 5,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "got to be joking -",
                "page": 5,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "He heads out the door - texting as he goes into -",
                "page": 5,
                "x0": 108,
                "x1": 451.18080000000015,
                "y0": 432.592,
                "y1": 444.592
            }
        ],
        "scene_number": "6",
        "int_ext": "INT.",
        "location": "HOTEL ROOM",
        "time": "DAY"
    },
    {
        "text": "7 INT. CORRIDOR - HOTEL - DAY 7",
        "page": 5,
        "lines": [
            {
                "text": "Charlie strides to the next room, raps on the door.",
                "page": 5,
                "x0": 108,
                "x1": 465.1800000000002,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "CHARLIE",
                "page": 5,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "Gerard. It’s Charlie. Come to the",
                "page": 5,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "door.",
                "page": 5,
                "x0": 180,
                "x1": 215.1984,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "A few moments, Charlie reads his text again.",
                "page": 5,
                "x0": 108,
                "x1": 416.1828000000001,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "CHARLIE (CONT’D)",
                "page": 5,
                "x0": 252,
                "x1": 364.1939999999999,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "Fuck.",
                "page": 5,
                "x0": 180,
                "x1": 215.1984,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "He can hear someone inside.",
                "page": 5,
                "x0": 108,
                "x1": 297.18959999999987,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "CHARLIE (CONT’D)",
                "page": 5,
                "x0": 252,
                "x1": 364.1939999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "Come to the fucking door Gerard.",
                "page": 5,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "LEWIS opens the door. Charlie pushes his way in. Lewis is",
                "page": 5,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "in a robe and pajamas.",
                "page": 5,
                "x0": 108,
                "x1": 262.19159999999994,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 3.",
                "page": 6,
                "x0": 90,
                "x1": 522.1996,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            }
        ],
        "scene_number": "7",
        "int_ext": "INT.",
        "location": "CORRIDOR - HOTEL",
        "time": "DAY"
    },
    {
        "text": "7a INT. LEWIS’ HOTEL SUITE - CONTINUOUS 7a",
        "page": 6,
        "lines": [
            {
                "text": "CHARLIE",
                "page": 6,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "I’ve got bad news, have you heard?",
                "page": 6,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "Ed Sproule died last night. Heart",
                "page": 6,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "attack -",
                "page": 6,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "LEWIS",
                "page": 6,
                "x0": 252,
                "x1": 287.1984,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "What?",
                "page": 6,
                "x0": 180,
                "x1": 215.1984,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "CHARLIE",
                "page": 6,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "Your cousin, he’s dead. You",
                "page": 6,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "haven’t heard. Are you alone?",
                "page": 6,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "LEWIS",
                "page": 6,
                "x0": 252,
                "x1": 287.1984,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "Yes, yes, of course -",
                "page": 6,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "CHARLIE",
                "page": 6,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "My condolences, but we are quite",
                "page": 6,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "possibly fucked.",
                "page": 6,
                "x0": 180,
                "x1": 292.1939999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "LEWIS",
                "page": 6,
                "x0": 252,
                "x1": 287.1984,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "Good christ. What awful bloody",
                "page": 6,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "timing. I can’t believe it.",
                "page": 6,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "CHARLIE",
                "page": 6,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "Well, there’s a solution. We’ve",
                "page": 6,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "got that other fellow, Al Crenshaw,",
                "page": 6,
                "x0": 180,
                "x1": 425.2,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "we can slot him in. We don’t know",
                "page": 6,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "exactly where he stands on our",
                "page": 6,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "program, but he’ll come around I",
                "page": 6,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "suppose.",
                "page": 6,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "LEWIS",
                "page": 6,
                "x0": 252,
                "x1": 287.1984,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "We’ll find out. Maybe he’s a",
                "page": 6,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "temporary fix. An interim player,",
                "page": 6,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "six months, a year, until we find",
                "page": 6,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "the Right Man.",
                "page": 6,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "CHARLIE",
                "page": 6,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "Well? Is this a fire we let burn",
                "page": 6,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "or do we try and put it out.",
                "page": 6,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "LEWIS",
                "page": 6,
                "x0": 252,
                "x1": 287.1984,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "What story do we tell.",
                "page": 6,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "CHARLIE",
                "page": 6,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "I think we simply dodge the",
                "page": 6,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "question. If asked, we simply say",
                "page": 6,
                "x0": 180,
                "x1": 418.19479999999993,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "Edmund Sproule was never even in",
                "page": 6,
                "x0": 180,
                "x1": 404.1903999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "the running for the top job, we’ve",
                "page": 6,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "always had our eye on Al Crenshaw.",
                "page": 6,
                "x0": 180,
                "x1": 418.2,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 6,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 4.",
                "page": 7,
                "x0": 90,
                "x1": 522.1996,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "7a CONTINUED: 7a",
                "page": 7,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "LEWIS",
                "page": 7,
                "x0": 252,
                "x1": 287.1984,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Al Crenshaw. The voice of reason",
                "page": 7,
                "x0": 180,
                "x1": 411.19159999999994,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "and experience.",
                "page": 7,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "CHARLIE",
                "page": 7,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "You’ll have to put the arm on him",
                "page": 7,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "pronto.",
                "page": 7,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            }
        ],
        "scene_number": "7a",
        "int_ext": "INT.",
        "location": "LEWIS’ HOTEL SUITE",
        "time": "CONTINUOUS"
    },
    {
        "text": "8 INT. SAFE HOUSE - DAY 8",
        "page": 7,
        "lines": [
            {
                "text": "A dead Sproule stares back at Al. Al rises from his stoop.",
                "page": 7,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "AL",
                "page": 7,
                "x0": 252,
                "x1": 266.1996,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "He was found like this?",
                "page": 7,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "Al looks to the room - it’s got no evidence of things awry.",
                "page": 7,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "DETECTIVE GREEN probably has better things to do.",
                "page": 7,
                "x0": 108,
                "x1": 451.18080000000015,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 7,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "Just like this. Coroner says it",
                "page": 7,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "looks like a heart attack.",
                "page": 7,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "AL",
                "page": 7,
                "x0": 252,
                "x1": 266.1996,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "A heart attack.",
                "page": 7,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 7,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "No signs of foul play or forced",
                "page": 7,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "entry. Bloodshot eyes. Autopsy",
                "page": 7,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "will confirm it.",
                "page": 7,
                "x0": 180,
                "x1": 292.19559999999996,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Al looks around. Nervous.",
                "page": 7,
                "x0": 108,
                "x1": 290.1899999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "AL",
                "page": 7,
                "x0": 252,
                "x1": 266.1996,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "There’ll be an autopsy. No",
                "page": 7,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "question about that. You’re in",
                "page": 7,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "homicide - why are you here.",
                "page": 7,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 7,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "The Coroner said he’s a pretty big",
                "page": 7,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "wheel, might get some scrutiny and",
                "page": 7,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "wanted a second opinion.",
                "page": 7,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "AL",
                "page": 7,
                "x0": 252,
                "x1": 266.1996,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "He was, yes. A big wheel indeed.",
                "page": 7,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "How is it you knew to call me.",
                "page": 7,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 7,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "In his wallet, there’s a card. It",
                "page": 7,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "said to call your number in an",
                "page": 7,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "emergency.",
                "page": 7,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 7,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 5.",
                "page": 8,
                "x0": 90,
                "x1": 522.1996,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "8 CONTINUED: 8",
                "page": 8,
                "x0": 54,
                "x1": 526.2,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "AL",
                "page": 8,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Right.",
                "page": 8,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 8,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "You’re with the Intel Service as",
                "page": 8,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "well.",
                "page": 8,
                "x0": 180,
                "x1": 215.1984,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "AL",
                "page": 8,
                "x0": 252,
                "x1": 266.1996,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Yes. I am.",
                "page": 8,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 8,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "I guess you were close.",
                "page": 8,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "AL",
                "page": 8,
                "x0": 252,
                "x1": 266.1996,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "We were. I’m glad you called me.",
                "page": 8,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "Al takes a beat.",
                "page": 8,
                "x0": 108,
                "x1": 220.19399999999993,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "AL (CONT’D)",
                "page": 8,
                "x0": 252,
                "x1": 329.19599999999997,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "May I?",
                "page": 8,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "He indicates the bedroom.",
                "page": 8,
                "x0": 108,
                "x1": 283.1903999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 8,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "Go ahead. Probably don’t want to",
                "page": 8,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "touch anything.",
                "page": 8,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "Al steps into the room. Glances towards the hidden camera",
                "page": 8,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "on the wall.",
                "page": 8,
                "x0": 108,
                "x1": 192.19559999999996,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "DETECTIVE GREEN (CONT’D)",
                "page": 8,
                "x0": 252,
                "x1": 420.1907999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "It looks like he was just having a",
                "page": 8,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "glass of wine.",
                "page": 8,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "As he speaks, Al takes in the crime scene. Noting things.",
                "page": 8,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "AL",
                "page": 8,
                "x0": 252,
                "x1": 266.1996,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "He always enjoyed a good glass.",
                "page": 8,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "And the high life. Rich food,",
                "page": 8,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "everything in butter. Loved his",
                "page": 8,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "butter. Never put on a pound of",
                "page": 8,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "weight. Skinny as a rail to the",
                "page": 8,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "end. Never knew him to favour",
                "page": 8,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "pate. Is that what that is?",
                "page": 8,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "He points to the pate.",
                "page": 8,
                "x0": 108,
                "x1": 262.19159999999994,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 8,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "I think so. Liver.",
                "page": 8,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "Al glances around the room again.",
                "page": 8,
                "x0": 108,
                "x1": 339.1871999999999,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 8,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Green (09/09/2016) 6.",
                "page": 9,
                "x0": 90,
                "x1": 522.1996,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "8 CONTINUED: (2) 8",
                "page": 9,
                "x0": 54,
                "x1": 526.2,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "AL",
                "page": 9,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Liver. Do you mind if I have a",
                "page": 9,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "moment alone. I’d like to say a",
                "page": 9,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "few things to him I never got the",
                "page": 9,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "chance to say to his face.",
                "page": 9,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 9,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "Go ahead. Don’t mind me. I’m just",
                "page": 9,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "waiting on body pickup.",
                "page": 9,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "AL",
                "page": 9,
                "x0": 252,
                "x1": 266.1996,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "Does that mean you’re finished",
                "page": 9,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "here. It’s a heart attack. No",
                "page": 9,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "signs of foul play.",
                "page": 9,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 9,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "Toxicology might prove it",
                "page": 9,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "otherwise, but it’s a fair guess",
                "page": 9,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "that’s what it appears to be.",
                "page": 9,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "Unless you know different -",
                "page": 9,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "AL",
                "page": 9,
                "x0": 252,
                "x1": 266.1996,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "No, no. Thank you.",
                "page": 9,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "The Detective steps away. Giving him a little space. But",
                "page": 9,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "not enough.",
                "page": 9,
                "x0": 108,
                "x1": 185.19599999999997,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 9,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "I’ll give you a moment.",
                "page": 9,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "Al edges towards the HIDDEN CAMERA. Reaches for it. But the",
                "page": 9,
                "x0": 108,
                "x1": 535.1872,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Detective reappears. She’s lingering. So Al drifts back to",
                "page": 9,
                "x0": 108,
                "x1": 528.186,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "Sproule lying there, staring back at him.",
                "page": 9,
                "x0": 108,
                "x1": 395.184,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "AL",
                "page": 9,
                "x0": 252,
                "x1": 266.1996,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "Goodbye, my old friend.",
                "page": 9,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 492.592,
                "y1": 504.592
            }
        ],
        "scene_number": "8",
        "int_ext": "INT.",
        "location": "SAFE HOUSE",
        "time": "DAY"
    },
    {
        "text": "9 EXT. VANCOUVER - ESTABLISHING - DAY 9",
        "page": 9,
        "lines": [],
        "scene_number": "9",
        "int_ext": "EXT.",
        "location": "VANCOUVER",
        "time": "DAY"
    },
    {
        "text": "10 INT. WOLFGANG’S OFFICE - DAY 10",
        "page": 9,
        "lines": [
            {
                "text": "On his computer screen WOLFGANG has the website for EXCLUSIVE",
                "page": 9,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "GOLDEN ESCORT SERVICES. He clicks on ESCORTS and a selection",
                "page": 9,
                "x0": 108,
                "x1": 535.194,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "of the escorts available comes up. He goes through many,",
                "page": 9,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "then finds what he’s looking for, under the rubric CANDY.",
                "page": 9,
                "x0": 108,
                "x1": 507.2,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "He clicks on CANDY. We should recognize her.",
                "page": 9,
                "x0": 108,
                "x1": 423.18959999999987,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "And up comes the photo gallery of the ESCORT - CANDY. His",
                "page": 9,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "phone RINGS. Wolfgang answers.",
                "page": 9,
                "x0": 108,
                "x1": 325.1879999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 9,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Salmon (09/13/2016) 7.",
                "page": 10,
                "x0": 90,
                "x1": 522.1996,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "10 CONTINUED: 10",
                "page": 10,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "WOLFGANG",
                "page": 10,
                "x0": 252,
                "x1": 308.1972,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "(into phone)",
                "page": 10,
                "x0": 207,
                "x1": 291.19559999999996,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "Hello. It is.",
                "page": 10,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "As he listens, he already starts to rise from his chair and",
                "page": 10,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "head out the door as:",
                "page": 10,
                "x0": 108,
                "x1": 255.1919999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "WOLFGANG (CONT’D)",
                "page": 10,
                "x0": 252,
                "x1": 371.19359999999995,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "(into phone)",
                "page": 10,
                "x0": 207,
                "x1": 291.19559999999996,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "Try and keep him there, I’ll be ten",
                "page": 10,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "minutes.",
                "page": 10,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "And he’s gone.",
                "page": 10,
                "x0": 108,
                "x1": 206.19479999999993,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            }
        ],
        "scene_number": "10",
        "int_ext": "INT.",
        "location": "WOLFGANG’S OFFICE",
        "time": "DAY"
    },
    {
        "text": "11 EXT. MORGUE - REAR - DAY 11 *",
        "page": 10,
        "lines": [
            {
                "text": "AN ORDERLY hustles NORMAN out of the exit doors.",
                "page": 10,
                "x0": 108,
                "x1": 444.1852,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "NORMAN",
                "page": 10,
                "x0": 252,
                "x1": 294.198,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "It’s a simple request. I have a *",
                "page": 10,
                "x0": 180,
                "x1": 558.2,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "right to see the autopsy results.",
                "page": 10,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "At least tell the pathologist to do",
                "page": 10,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "the blood work quickly so we can",
                "page": 10,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "know what drugs she had on board.",
                "page": 10,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "Because it’s not a suicide.",
                "page": 10,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "The doors are shut on him. He pounds the door.",
                "page": 10,
                "x0": 108,
                "x1": 437.1816000000001,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "NORMAN (CONT’D)",
                "page": 10,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "It’s not a suicide!",
                "page": 10,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Norman looks at the WINDOW where the PATHOLOGIST watches. *",
                "page": 10,
                "x0": 108,
                "x1": 558.2,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "NORMAN (CONT’D)",
                "page": 10,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "I see you there. It’s not a",
                "page": 10,
                "x0": 180,
                "x1": 376.19319999999993,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "suicide. She did not kill herself. *",
                "page": 10,
                "x0": 180,
                "x1": 558.2,
                "y0": 504.592,
                "y1": 516.5921
            },
            {
                "text": "You hear me!",
                "page": 10,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "She steps away from the window. Norman storms away.",
                "page": 10,
                "x0": 108,
                "x1": 472.1852,
                "y0": 540.592,
                "y1": 552.592
            }
        ],
        "scene_number": "11",
        "int_ext": "EXT.",
        "location": "MORGUE - REAR",
        "time": "DAY"
    },
    {
        "text": "12 EXT. PRC BUILDING - DAY 12",
        "page": 10,
        "lines": [
            {
                "text": "Al, shaken, returns to his office, distracted. A black town",
                "page": 10,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "car slips past him and stops just ahead. Out of the rear",
                "page": 10,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "lurches GERARD LEWIS.",
                "page": 10,
                "x0": 108,
                "x1": 255.1919999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "LEWIS",
                "page": 10,
                "x0": 252,
                "x1": 287.1984,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "Al Crenshaw -",
                "page": 10,
                "x0": 180,
                "x1": 271.1996,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "(CONTINUED)",
                "page": 10,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 8.",
                "page": 11,
                "x0": 90,
                "x1": 522.1996,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "12 CONTINUED: 12",
                "page": 11,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "Al hitches up at the entrance -",
                "page": 11,
                "x0": 108,
                "x1": 325.1879999999999,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "LEWIS (CONT’D)",
                "page": 11,
                "x0": 252,
                "x1": 350.19479999999993,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "Deputy Minister Gerard Lewis.",
                "page": 11,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "Edmund Sproule’s cousin - have you",
                "page": 11,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "got a minute?",
                "page": 11,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "AL",
                "page": 11,
                "x0": 252,
                "x1": 266.1996,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Oh - yes Minister. How may I help",
                "page": 11,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "you.",
                "page": 11,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "LEWIS",
                "page": 11,
                "x0": 252,
                "x1": 287.1984,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "I’ve just heard about Edmund - are",
                "page": 11,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "you aware he passed away this",
                "page": 11,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "morning.",
                "page": 11,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "AL",
                "page": 11,
                "x0": 252,
                "x1": 266.1996,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "Oh, yes, my condolences, I’m very",
                "page": 11,
                "x0": 180,
                "x1": 411.1968,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "sorry to hear about it. Can you",
                "page": 11,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "give me any details? What",
                "page": 11,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "happened.",
                "page": 11,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "LEWIS",
                "page": 11,
                "x0": 252,
                "x1": 287.1984,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "I appreciate you’re busy so I’ll",
                "page": 11,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "get straight to the point. As you",
                "page": 11,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "know Edmund had been selected for a",
                "page": 11,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "senior intelligence job in Ottawa.",
                "page": 11,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "AL",
                "page": 11,
                "x0": 252,
                "x1": 266.1996,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "Yes, I was aware.",
                "page": 11,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "LEWIS",
                "page": 11,
                "x0": 252,
                "x1": 287.1984,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "You were our very close second",
                "page": 11,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "choice by the way -",
                "page": 11,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "AL",
                "page": 11,
                "x0": 252,
                "x1": 266.1996,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "Very flattered.",
                "page": 11,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "LEWIS",
                "page": 11,
                "x0": 252,
                "x1": 287.1984,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Here’s our situation. It’s",
                "page": 11,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "important we find a temporary",
                "page": 11,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "replacement for Edmund and your",
                "page": 11,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "name naturally rose to the top of",
                "page": 11,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "the list.",
                "page": 11,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "AL",
                "page": 11,
                "x0": 252,
                "x1": 266.1996,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "A temporary replacement -",
                "page": 11,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "LEWIS",
                "page": 11,
                "x0": 252,
                "x1": 287.1984,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "Yes. You’ve already been vetted,",
                "page": 11,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "it’s a matter of fast tracking",
                "page": 11,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "approval.",
                "page": 11,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(MORE)",
                "page": 11,
                "x0": 252,
                "x1": 294.198,
                "y0": 696.5921,
                "y1": 708.5921
            },
            {
                "text": "(CONTINUED)",
                "page": 11,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 9.",
                "page": 12,
                "x0": 90,
                "x1": 522.1996,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "12 CONTINUED: (2) 12",
                "page": 12,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "LEWIS (CONT'D)",
                "page": 12,
                "x0": 252,
                "x1": 350.19479999999993,
                "y0": 60.591999999999985,
                "y1": 72.59199999999998
            },
            {
                "text": "If we call it temporary that helps,",
                "page": 12,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "but it also means you’re in",
                "page": 12,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "position to take over permanently",
                "page": 12,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "in six months or so.",
                "page": 12,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "AL",
                "page": 12,
                "x0": 252,
                "x1": 266.1996,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "Awfully sudden. I’m still trying",
                "page": 12,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "to absorb the fact that Ed is no",
                "page": 12,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "longer with us.",
                "page": 12,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "LEWIS",
                "page": 12,
                "x0": 252,
                "x1": 287.1984,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "Of course. It’s a shock. You’ll",
                "page": 12,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "need time to consider.",
                "page": 12,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "Al takes a beat.",
                "page": 12,
                "x0": 108,
                "x1": 220.19399999999993,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "LEWIS (CONT’D)",
                "page": 12,
                "x0": 252,
                "x1": 350.1968,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "I understand it’s a bit",
                "page": 12,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "overwhelming but you’d be doing us",
                "page": 12,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "all an enormous service. We can’t",
                "page": 12,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "leave the ship at sea with no one",
                "page": 12,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "at the helm, yes. Circumstances",
                "page": 12,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "demand we press on, yes.",
                "page": 12,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "AL",
                "page": 12,
                "x0": 252,
                "x1": 266.1996,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "I’ll consider it.",
                "page": 12,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "LEWIS",
                "page": 12,
                "x0": 252,
                "x1": 287.1984,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "That’s all we can ask.",
                "page": 12,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "A confidential look.",
                "page": 12,
                "x0": 108,
                "x1": 248.1923999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "LEWIS (CONT’D)",
                "page": 12,
                "x0": 252,
                "x1": 350.1968,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "He didn’t die at home. He was",
                "page": 12,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "camping out at a safe house, living",
                "page": 12,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "out of a suitcase. His wife Evelyn",
                "page": 12,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "suggests he was having an affair.",
                "page": 12,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "AL",
                "page": 12,
                "x0": 252,
                "x1": 266.1996,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Aha.",
                "page": 12,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "LEWIS",
                "page": 12,
                "x0": 252,
                "x1": 287.1984,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "If anyone should inquire, and they",
                "page": 12,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "will, he died at his marital home,",
                "page": 12,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "in bed, from a cardiac arrest.",
                "page": 12,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "Yes?",
                "page": 12,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "AL",
                "page": 12,
                "x0": 252,
                "x1": 266.1996,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "Is that the Coroner’s ruling.",
                "page": 12,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "LEWIS",
                "page": 12,
                "x0": 252,
                "x1": 287.1984,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "I believe it will be. One other",
                "page": 12,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "thing.",
                "page": 12,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(MORE)",
                "page": 12,
                "x0": 252,
                "x1": 294.198,
                "y0": 708.5921,
                "y1": 720.5921
            },
            {
                "text": "(CONTINUED)",
                "page": 12,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Green (09/09/2016) 10.",
                "page": 13,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "12 CONTINUED: (3) 12",
                "page": 13,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "LEWIS (CONT'D)",
                "page": 13,
                "x0": 252,
                "x1": 350.19479999999993,
                "y0": 60.591999999999985,
                "y1": 72.59199999999998
            },
            {
                "text": "We’re saying that Edmund was never",
                "page": 13,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "approved for the hiring. We’re",
                "page": 13,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "saying that you were our first",
                "page": 13,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "choice. I hope you’re alright with",
                "page": 13,
                "x0": 180,
                "x1": 425.1984,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "that.",
                "page": 13,
                "x0": 180,
                "x1": 215.1984,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            }
        ],
        "scene_number": "12",
        "int_ext": "EXT.",
        "location": "PRC BUILDING",
        "time": "DAY"
    },
    {
        "text": "14 INT. PRC BUILDING - FOYER - DAY 14",
        "page": 13,
        "lines": [
            {
                "text": "Al watches the limousine pull away. Sweat curls along his",
                "page": 13,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "lip.",
                "page": 13,
                "x0": 108,
                "x1": 136.19879999999998,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            }
        ],
        "scene_number": "14",
        "int_ext": "INT.",
        "location": "PRC BUILDING - FOYER",
        "time": "DAY"
    },
    {
        "text": "15 INT. AL’S OFFICE - DAY 15",
        "page": 13,
        "lines": [
            {
                "text": "Al is hunched over his computer, leaning into it, watching",
                "page": 13,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "the live feed of the Safe House.",
                "page": 13,
                "x0": 108,
                "x1": 332.1875999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "ON THE MONITOR:",
                "page": 13,
                "x0": 108,
                "x1": 213.19439999999994,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "BODY PICKUP is there, removing Sproule’s body from the crime",
                "page": 13,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "scene. Detective Green is still there, taking photos of the",
                "page": 13,
                "x0": 108,
                "x1": 528.1923999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "food.",
                "page": 13,
                "x0": 108,
                "x1": 143.1984,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "Al sweats as Green walks right up to the camera. Finally,",
                "page": 13,
                "x0": 108,
                "x1": 514.1972000000001,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "Green moves on and continues snooping around the safe house.",
                "page": 13,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 408.592,
                "y1": 420.592
            }
        ],
        "scene_number": "15",
        "int_ext": "INT.",
        "location": "AL’S OFFICE",
        "time": "DAY"
    },
    {
        "text": "16 INT. MORGUE - CORRIDOR - DAY 16",
        "page": 13,
        "lines": [
            {
                "text": "The PATHOLOGIST arrives to meet Wolfgang. She should be",
                "page": 13,
                "x0": 108,
                "x1": 493.19479999999993,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "familiar to us.",
                "page": 13,
                "x0": 108,
                "x1": 213.19439999999994,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "PATHOLOGIST",
                "page": 13,
                "x0": 252,
                "x1": 329.19599999999997,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "Your partner there, he’s got a",
                "page": 13,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "nasty streak in him. Were you",
                "page": 13,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "aware he bites?",
                "page": 13,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "WOLFGANG",
                "page": 13,
                "x0": 252,
                "x1": 308.1972,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "He can be a little earnest on",
                "page": 13,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "occasion.",
                "page": 13,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "PATHOLOGIST",
                "page": 13,
                "x0": 252,
                "x1": 329.19599999999997,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "He was threatening to expose what",
                "page": 13,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "he called “my complicity”. He does",
                "page": 13,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "that and I will sue you both and",
                "page": 13,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "your insurance company for",
                "page": 13,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "defamation.",
                "page": 13,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 13,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Green (09/09/2016) 11.",
                "page": 14,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "16 CONTINUED: 16",
                "page": 14,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "WOLFGANG",
                "page": 14,
                "x0": 252,
                "x1": 308.1972,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "I promise that won’t happen. Did",
                "page": 14,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "he happen to mention where he might",
                "page": 14,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "have been heading?",
                "page": 14,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "PATHOLOGIST",
                "page": 14,
                "x0": 252,
                "x1": 329.19599999999997,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "No. Here. I remember you asking",
                "page": 14,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "for it the last time you were here",
                "page": 14,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "questioning my integrity.",
                "page": 14,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "She hands him an envelope with a form inside.",
                "page": 14,
                "x0": 108,
                "x1": 423.1824000000001,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "PATHOLOGIST (CONT’D)",
                "page": 14,
                "x0": 252,
                "x1": 392.1923999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "The toxicology report on the",
                "page": 14,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "Security Guard who committed",
                "page": 14,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "suicide.",
                "page": 14,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "WOLFGANG",
                "page": 14,
                "x0": 252,
                "x1": 308.1972,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "Ah, brilliant.",
                "page": 14,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "He glances at it. Raises a brow.",
                "page": 14,
                "x0": 108,
                "x1": 339.1871999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "WOLFGANG (CONT’D)",
                "page": 14,
                "x0": 252,
                "x1": 371.19359999999995,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "I can keep this.",
                "page": 14,
                "x0": 180,
                "x1": 292.1939999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "PATHOLOGIST",
                "page": 14,
                "x0": 252,
                "x1": 329.19599999999997,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "If you promise to keep your dogs",
                "page": 14,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "off me.",
                "page": 14,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "WOLFGANG",
                "page": 14,
                "x0": 252,
                "x1": 308.1972,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Do my best. Perhaps you’ll be",
                "page": 14,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "seeing a young woman who drowned",
                "page": 14,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "coming through here if she hasn’t",
                "page": 14,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "already -",
                "page": 14,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "PATHOLOGIST",
                "page": 14,
                "x0": 252,
                "x1": 329.19599999999997,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "Yes, your partner made me very",
                "page": 14,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "aware of that -",
                "page": 14,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "WOLFGANG",
                "page": 14,
                "x0": 252,
                "x1": 308.1972,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "I’d appreciate a call.",
                "page": 14,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "Wolfgang walks off.",
                "page": 14,
                "x0": 108,
                "x1": 241.19279999999992,
                "y0": 576.592,
                "y1": 588.592
            }
        ],
        "scene_number": "16",
        "int_ext": "INT.",
        "location": "MORGUE - CORRIDOR",
        "time": "DAY"
    },
    {
        "text": "17 EXT./INT. WOLFGANG’S JEEP - DAY 17",
        "page": 14,
        "lines": [
            {
                "text": "As he sits in, his phone RINGS. He checks the TEXT.",
                "page": 14,
                "x0": 108,
                "x1": 479.1792000000002,
                "y0": 636.592,
                "y1": 648.592
            }
        ],
        "scene_number": "17",
        "int_ext": "EXT., INT.",
        "location": "WOLFGANG’S JEEP",
        "time": "DAY"
    },
    {
        "text": "18 EXT. BENEATH THE BURRARD BRIDGE - DAY 18",
        "page": 14,
        "lines": [
            {
                "text": "The harbour front runs opposite.",
                "page": 14,
                "x0": 108,
                "x1": 332.1875999999999,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 14,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 12.",
                "page": 15,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "18 CONTINUED: 18",
                "page": 15,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "WOLFGANG",
                "page": 15,
                "x0": 252,
                "x1": 308.1972,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Sproule? When did this happen.",
                "page": 15,
                "x0": 180,
                "x1": 390.1919999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "HARRY",
                "page": 15,
                "x0": 252,
                "x1": 287.1984,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "Last night sometime. Alone, in a",
                "page": 15,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "safe house here in the city.",
                "page": 15,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 15,
                "x0": 252,
                "x1": 308.1972,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Heart attack you said.",
                "page": 15,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "HARRY",
                "page": 15,
                "x0": 252,
                "x1": 287.1984,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "That’s what I’ve been told.",
                "page": 15,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 15,
                "x0": 252,
                "x1": 308.1972,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "You’re skeptical.",
                "page": 15,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "HARRY",
                "page": 15,
                "x0": 252,
                "x1": 287.1984,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "Bear with me. I’ve been puzzling",
                "page": 15,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "over this. Eighteen months ago -",
                "page": 15,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "the Chief of the Intelligence",
                "page": 15,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "Service dies in his sleep.",
                "page": 15,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "WOLFGANG",
                "page": 15,
                "x0": 252,
                "x1": 308.1972,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "I remember.",
                "page": 15,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "HARRY",
                "page": 15,
                "x0": 252,
                "x1": 287.1984,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "Also a heart attack. The job was",
                "page": 15,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "then held by an interim appointment",
                "page": 15,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "until Mr. Sproule won the position.",
                "page": 15,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "He was due to be installed in",
                "page": 15,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Ottawa this month but as of this",
                "page": 15,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "morning, he’s now dead. All well",
                "page": 15,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "and good, two successive",
                "page": 15,
                "x0": 180,
                "x1": 348.1964,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "Intelligence Chiefs die in their",
                "page": 15,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "sleep from heart attacks. Long",
                "page": 15,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "odds, but not impossible.",
                "page": 15,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "Stressful job.",
                "page": 15,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "WOLFGANG",
                "page": 15,
                "x0": 252,
                "x1": 308.1972,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Right.",
                "page": 15,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "He takes a beat. He’s got Wolfgang’s full attention.",
                "page": 15,
                "x0": 108,
                "x1": 479.1792000000002,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "HARRY",
                "page": 15,
                "x0": 252,
                "x1": 287.1984,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "I have recently discovered that the",
                "page": 15,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "subject of your investigation, Mr.",
                "page": 15,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "Michael Gary, has been on the short",
                "page": 15,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "list to head the spy service for a",
                "page": 15,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "few years now -",
                "page": 15,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "WOLFGANG",
                "page": 15,
                "x0": 252,
                "x1": 308.1972,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "You’re joking. How does that",
                "page": 15,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "happen - with his past record.",
                "page": 15,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 15,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 13.",
                "page": 16,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "18 CONTINUED: (2) 18",
                "page": 16,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "HARRY",
                "page": 16,
                "x0": 252,
                "x1": 287.1984,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Placed there by the previous regime",
                "page": 16,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "and far enough down the list not to",
                "page": 16,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "present any worries, so it was",
                "page": 16,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "ignored.",
                "page": 16,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 16,
                "x0": 252,
                "x1": 308.1972,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "But now -",
                "page": 16,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "HARRY",
                "page": 16,
                "x0": 252,
                "x1": 287.1984,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "But now, as the bodies fall, that",
                "page": 16,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "short list gets ever shorter and",
                "page": 16,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "Mr. Gary’s name gets closer to the",
                "page": 16,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "top. Given another year or two,",
                "page": 16,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "depending upon circumstances - he",
                "page": 16,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "might well be the man running",
                "page": 16,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "things.",
                "page": 16,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "Wolfgang appears skeptical.",
                "page": 16,
                "x0": 108,
                "x1": 297.18959999999987,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "WOLFGANG",
                "page": 16,
                "x0": 252,
                "x1": 308.1972,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "He appears to be well occupied",
                "page": 16,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "building a private empire at this",
                "page": 16,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "point.",
                "page": 16,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "HARRY",
                "page": 16,
                "x0": 252,
                "x1": 287.1984,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "These sorts of men, like myself, go",
                "page": 16,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "back and forth from private sector",
                "page": 16,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "to government, don’t they. And",
                "page": 16,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "finally, it’s not as if Mr. Gary",
                "page": 16,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "hasn’t been involved in these sorts",
                "page": 16,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "of things before.",
                "page": 16,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "WOLFGANG",
                "page": 16,
                "x0": 252,
                "x1": 308.1972,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "These sorts of things.",
                "page": 16,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "HARRY",
                "page": 16,
                "x0": 252,
                "x1": 287.1984,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "Regime change.",
                "page": 16,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 516.592,
                "y1": 528.592
            }
        ],
        "scene_number": "18",
        "int_ext": "EXT.",
        "location": "BENEATH THE BURRARD BRIDGE",
        "time": "DAY"
    },
    {
        "text": "19 INT. AL’S OFFICE - DAY 19",
        "page": 16,
        "lines": [
            {
                "text": "Al is REWINDING the recorded tapes - he punches STOP -",
                "page": 16,
                "x0": 108,
                "x1": 486.17880000000025,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "ON THE MONITOR:",
                "page": 16,
                "x0": 108,
                "x1": 213.19439999999994,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "SPROULE as he slumps by the door jam.",
                "page": 16,
                "x0": 108,
                "x1": 367.18559999999997,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "LILY",
                "page": 16,
                "x0": 252,
                "x1": 280.1988,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "You’re a foolish old man, aren’t",
                "page": 16,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "you?",
                "page": 16,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "She stoops to take the gun from Sproule’s grasp.",
                "page": 16,
                "x0": 108,
                "x1": 444.18120000000016,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 16,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 14.",
                "page": 17,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "19 CONTINUED: 19",
                "page": 17,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "LILY (CONT’D)",
                "page": 17,
                "x0": 252,
                "x1": 343.19519999999994,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "You’re having a heart attack. Not",
                "page": 17,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "much to do about it.",
                "page": 17,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "She goes about wiping her prints from the room. Al watches,",
                "page": 17,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "riveted and aghast. He REWINDS again. Now to the earlier",
                "page": 17,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "part of the scene:",
                "page": 17,
                "x0": 108,
                "x1": 234.19319999999993,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "Lily offers a GLASS to Sproule.",
                "page": 17,
                "x0": 108,
                "x1": 325.1879999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "LILY (CONT’D)",
                "page": 17,
                "x0": 252,
                "x1": 343.19519999999994,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "Tell me if this tastes off to you.",
                "page": 17,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "Al rewinds again.",
                "page": 17,
                "x0": 108,
                "x1": 227.19359999999995,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "LILY (CONT’D)",
                "page": 17,
                "x0": 252,
                "x1": 343.19519999999994,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "Tell me if this tastes off to you.",
                "page": 17,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 264.592,
                "y1": 276.592
            }
        ],
        "scene_number": "19",
        "int_ext": "INT.",
        "location": "AL’S OFFICE",
        "time": "DAY"
    },
    {
        "text": "20 INT. EMPEROR’S QUARTERS - DAY (FOR NIGHT) 20",
        "page": 17,
        "lines": [
            {
                "text": "FAN FAN (MEI MEI) slips along the darkened courtyard to find",
                "page": 17,
                "x0": 108,
                "x1": 528.1828000000002,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "an anxious LI (XISHI) waiting by the arched exit. She brings",
                "page": 17,
                "x0": 108,
                "x1": 535.1840000000001,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "a basket of food.",
                "page": 17,
                "x0": 108,
                "x1": 227.19359999999995,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "LI",
                "page": 17,
                "x0": 252,
                "x1": 266.1996,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "Thank you Fan Fan. Your kindness",
                "page": 17,
                "x0": 180,
                "x1": 411.1939999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "is overwhelming.",
                "page": 17,
                "x0": 180,
                "x1": 292.1939999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "FAN FAN",
                "page": 17,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Anyone would do the same.",
                "page": 17,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "LI",
                "page": 17,
                "x0": 252,
                "x1": 266.1996,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "To think I hated you for so long,",
                "page": 17,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "for stealing my son’s love from me.",
                "page": 17,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "Now you risk your life to save both",
                "page": 17,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "his and mine.",
                "page": 17,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "FAN FAN",
                "page": 17,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "How is he?",
                "page": 17,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "LI",
                "page": 17,
                "x0": 252,
                "x1": 266.1996,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "Very weak. He has not had any",
                "page": 17,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "opium for days. He begs me for it.",
                "page": 17,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "I’m afraid he’ll risk death in",
                "page": 17,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "search of it.",
                "page": 17,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "FAN FAN",
                "page": 17,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "If he pokes his head out he will",
                "page": 17,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "lose it. The Emperor will put it",
                "page": 17,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "on the end of a stick. Where is",
                "page": 17,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "he?",
                "page": 17,
                "x0": 180,
                "x1": 201.1992,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 17,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 15.",
                "page": 18,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "20 CONTINUED: 20",
                "page": 18,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "LI",
                "page": 18,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "In the shed at the bottom of the",
                "page": 18,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "gardens by the pond.",
                "page": 18,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "FAN FAN",
                "page": 18,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "I will come to see him before you",
                "page": 18,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "go. I have found a man who will",
                "page": 18,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "take you south to the islands.",
                "page": 18,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "LI",
                "page": 18,
                "x0": 252,
                "x1": 266.1996,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "To the islands. But they are full",
                "page": 18,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "of pirates and outlaws. How will",
                "page": 18,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "we live?",
                "page": 18,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "FAN FAN",
                "page": 18,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "As the traitor and the mother of a",
                "page": 18,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "traitor how long do you think you",
                "page": 18,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "two might last if the Emperor",
                "page": 18,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "catches you? Take this food.",
                "page": 18,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "Prepare for a long journey. I will",
                "page": 18,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "get what money I can for you.",
                "page": 18,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "Reveal TONY behind the MONITOR giving Mei Mei a thumbs up.",
                "page": 18,
                "x0": 108,
                "x1": 514.1952,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "TONY",
                "page": 18,
                "x0": 252,
                "x1": 280.1988,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "That was great.",
                "page": 18,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "A.D. (O.S.)",
                "page": 18,
                "x0": 252,
                "x1": 329.19599999999997,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "Okay, moving on, blocking the next",
                "page": 18,
                "x0": 180,
                "x1": 418.1988,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "scene. Fan Fan and the Emperor.",
                "page": 18,
                "x0": 180,
                "x1": 404.19359999999995,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "P.A.",
                "page": 18,
                "x0": 252,
                "x1": 280.1988,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "Mr. Zhao?",
                "page": 18,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "Tony is approached with a message from a P.A. Mei Mei and",
                "page": 18,
                "x0": 108,
                "x1": 514.1988,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "the Emperor hover over their scripts and begin to read the",
                "page": 18,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "scene.",
                "page": 18,
                "x0": 108,
                "x1": 150.19799999999998,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "P.A. (CONT’D)",
                "page": 18,
                "x0": 252,
                "x1": 343.19519999999994,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Guy named Jimmy Wah left a message",
                "page": 18,
                "x0": 180,
                "x1": 418.1943999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "with the production office for you.",
                "page": 18,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "Tony is alarmed.",
                "page": 18,
                "x0": 108,
                "x1": 220.19399999999993,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "TONY",
                "page": 18,
                "x0": 252,
                "x1": 280.1988,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "Jimmy Wah.",
                "page": 18,
                "x0": 180,
                "x1": 250.2,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "P.A.",
                "page": 18,
                "x0": 252,
                "x1": 280.1988,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "Says he’ll see you on set after",
                "page": 18,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "wrap. He left his number.",
                "page": 18,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "He hands him a pink NOTE.",
                "page": 18,
                "x0": 108,
                "x1": 283.1903999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 18,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 16.",
                "page": 19,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "20 CONTINUED: (2) 20",
                "page": 19,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "TONY",
                "page": 19,
                "x0": 252,
                "x1": 280.1988,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Jimmy Wah? Are you sure?",
                "page": 19,
                "x0": 180,
                "x1": 355.1939999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "P.A.",
                "page": 19,
                "x0": 252,
                "x1": 280.1988,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "That’s what I was told.",
                "page": 19,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "The P.A. walks off. Tony fears the worst.",
                "page": 19,
                "x0": 108,
                "x1": 409.18879999999984,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            }
        ],
        "scene_number": "20",
        "int_ext": "INT.",
        "location": "EMPEROR’S QUARTERS",
        "time": "DAY"
    },
    {
        "text": "21 EXT. POLICE STATION - DAY 21",
        "page": 19,
        "lines": [
            {
                "text": "DETECTIVE TANAKA takes Norman by the arm and down the steps",
                "page": 19,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "and around the corner. A pair of DETECTIVES going in watch",
                "page": 19,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "them pass.",
                "page": 19,
                "x0": 108,
                "x1": 178.19639999999998,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "TANAKA",
                "page": 19,
                "x0": 252,
                "x1": 294.198,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "I really don’t fucking appreciate",
                "page": 19,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "you coming round here drawing",
                "page": 19,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "attention. What the fuck is the",
                "page": 19,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "matter with you.",
                "page": 19,
                "x0": 180,
                "x1": 292.1939999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "NORMAN",
                "page": 19,
                "x0": 252,
                "x1": 294.198,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "It’s not a suicide. Check the",
                "page": 19,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "blood work -",
                "page": 19,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "TANAKA",
                "page": 19,
                "x0": 252,
                "x1": 294.198,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "They haven’t even done the autopsy,",
                "page": 19,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "and it takes at least two weeks to",
                "page": 19,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "get tox results so calm the fuck",
                "page": 19,
                "x0": 180,
                "x1": 404.1903999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "down.",
                "page": 19,
                "x0": 180,
                "x1": 215.1984,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "NORMAN",
                "page": 19,
                "x0": 252,
                "x1": 294.198,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "Sonya Faye was the last person",
                "page": 19,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "alive to witness what happened at",
                "page": 19,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "the baseball stadium. She’s a",
                "page": 19,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "complete innocent and she’s been",
                "page": 19,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "murdered.",
                "page": 19,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "Tanaka takes a moment. Something in Norman’s manner puts him",
                "page": 19,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "on alert.",
                "page": 19,
                "x0": 108,
                "x1": 171.19679999999997,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "TANAKA",
                "page": 19,
                "x0": 252,
                "x1": 294.198,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "When did this happen, when was she",
                "page": 19,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "found.",
                "page": 19,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "NORMAN",
                "page": 19,
                "x0": 252,
                "x1": 294.198,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "Yesterday.",
                "page": 19,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "(CONTINUED)",
                "page": 19,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 17.",
                "page": 20,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "21 CONTINUED: 21",
                "page": 20,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "TANAKA",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Okay, this is the first I heard",
                "page": 20,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "about this and I don’t know what",
                "page": 20,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "the Coroner’s said, but I imagine",
                "page": 20,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "he’s going to wait for the",
                "page": 20,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "toxicology before he makes a ruling",
                "page": 20,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "- like he usually does -",
                "page": 20,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "Do you know what scopolamine is?",
                "page": 20,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "TANAKA",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "No.",
                "page": 20,
                "x0": 180,
                "x1": 201.1992,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "It’s a knockout drug. You happily",
                "page": 20,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "do whatever you’re asked to do, and",
                "page": 20,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "then you wake up and can’t remember",
                "page": 20,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "a fucking thing. Then you realize",
                "page": 20,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "that your furniture is gone, your",
                "page": 20,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "bank account is empty, and your",
                "page": 20,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "underwear is down around your",
                "page": 20,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "ankles. So when you do the blood",
                "page": 20,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "work, look for scopolamine or you",
                "page": 20,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "won’t find it.",
                "page": 20,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "TANAKA",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "I don’t do the blood tests -",
                "page": 20,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "NORMAN",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "I am very fucking aware of what you",
                "page": 20,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "don’t do my friend -",
                "page": 20,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "TANAKA",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "Are you gonna be an asshole your",
                "page": 20,
                "x0": 180,
                "x1": 404.1927999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "entire life -",
                "page": 20,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "NORMAN",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "And many lifetimes beyond this one -",
                "page": 20,
                "x0": 180,
                "x1": 432.186,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Wolfgang now appears. Tanaka sees him approach.",
                "page": 20,
                "x0": 108,
                "x1": 444.18120000000016,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "TANAKA",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "I hope you brought a leash -",
                "page": 20,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "NORMAN",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "I’m simply pointing out you should",
                "page": 20,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "know what you’re looking for so",
                "page": 20,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "when you see it you can fucking",
                "page": 20,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "recognize it - like murder -",
                "page": 20,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "TANAKA",
                "page": 20,
                "x0": 252,
                "x1": 294.198,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "Please take him away -",
                "page": 20,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 20,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 18.",
                "page": 21,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "21 CONTINUED: (2) 21",
                "page": 21,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "Wolf walks Norman away.",
                "page": 21,
                "x0": 108,
                "x1": 269.1911999999999,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 21,
                "x0": 252,
                "x1": 308.1972,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "Come. Let’s find you something to",
                "page": 21,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "eat.",
                "page": 21,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            }
        ],
        "scene_number": "21",
        "int_ext": "EXT.",
        "location": "POLICE STATION",
        "time": "DAY"
    },
    {
        "text": "22 INT. DINER - DAY 22",
        "page": 21,
        "lines": [
            {
                "text": "Norman finishes up a meal with a sigh.",
                "page": 21,
                "x0": 108,
                "x1": 374.1852,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 21,
                "x0": 252,
                "x1": 294.198,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "I’m sorry about going after the",
                "page": 21,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "Pathologist.",
                "page": 21,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 21,
                "x0": 252,
                "x1": 308.1972,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "And the Detective.",
                "page": 21,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "NORMAN",
                "page": 21,
                "x0": 252,
                "x1": 294.198,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "Him too. That’s wasn’t very",
                "page": 21,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "professional of me.",
                "page": 21,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "WOLFGANG",
                "page": 21,
                "x0": 252,
                "x1": 308.1972,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "That wasn’t even remotely",
                "page": 21,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "professional. But perhaps an",
                "page": 21,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "effective technique.",
                "page": 21,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "He tosses him the paper.",
                "page": 21,
                "x0": 108,
                "x1": 276.1907999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "WOLFGANG (CONT’D)",
                "page": 21,
                "x0": 252,
                "x1": 371.19359999999995,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "That’s the toxicology report on",
                "page": 21,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Kent. Our security man.",
                "page": 21,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "NORMAN",
                "page": 21,
                "x0": 252,
                "x1": 294.198,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "Where’d you get this.",
                "page": 21,
                "x0": 180,
                "x1": 327.19479999999993,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "WOLFGANG",
                "page": 21,
                "x0": 252,
                "x1": 308.1972,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "The Pathologist. Kent had",
                "page": 21,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "scopolamine in his blood when he",
                "page": 21,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "shot himself.",
                "page": 21,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "Norman looks closely at the report.",
                "page": 21,
                "x0": 108,
                "x1": 353.18639999999994,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "NORMAN",
                "page": 21,
                "x0": 252,
                "x1": 294.198,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "There you go. You sometimes just",
                "page": 21,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "gotta threaten people in the right",
                "page": 21,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "way, their assholes pucker, and",
                "page": 21,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "then they cough it up. What’d she",
                "page": 21,
                "x0": 180,
                "x1": 418.1988,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "say about Sonya.",
                "page": 21,
                "x0": 180,
                "x1": 292.1939999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "WOLFGANG",
                "page": 21,
                "x0": 252,
                "x1": 308.1972,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "She’ll let us know.",
                "page": 21,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 21,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 19.",
                "page": 22,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "22 CONTINUED: 22",
                "page": 22,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "Norman waves the toxicology report.",
                "page": 22,
                "x0": 108,
                "x1": 353.18639999999994,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 22,
                "x0": 252,
                "x1": 294.198,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "This means anything is possible. A",
                "page": 22,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "suicide becomes a murder.",
                "page": 22,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 22,
                "x0": 252,
                "x1": 308.1972,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "Anything is possible.",
                "page": 22,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            }
        ],
        "scene_number": "22",
        "int_ext": "INT.",
        "location": "DINER",
        "time": "DAY"
    },
    {
        "text": "23 INT. MOVIE SET - NIGHT 23",
        "page": 22,
        "lines": [
            {
                "text": "Mei Mei and the Emperor run lines and blocking while camera",
                "page": 22,
                "x0": 108,
                "x1": 521.1796000000003,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "sets up.",
                "page": 22,
                "x0": 108,
                "x1": 164.19719999999998,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "In the shadows, JIMMY WAH watches Mei Mei through the",
                "page": 22,
                "x0": 108,
                "x1": 479.19559999999996,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "scenery. A cigarette dangles from his lip. A NO SMOKING",
                "page": 22,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "sign just behind him. Tony appears.",
                "page": 22,
                "x0": 108,
                "x1": 360.186,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "TONY",
                "page": 22,
                "x0": 252,
                "x1": 280.1988,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "Mister Wah. Tony Zhao. This is a",
                "page": 22,
                "x0": 180,
                "x1": 418.1907999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "surprise. Welcome.",
                "page": 22,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "JIMMY WAH",
                "page": 22,
                "x0": 252,
                "x1": 315.19919999999996,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "Nice set. Very pretty.",
                "page": 22,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "He’s looking at Mei Mei the whole while.",
                "page": 22,
                "x0": 108,
                "x1": 388.19359999999995,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "TONY",
                "page": 22,
                "x0": 252,
                "x1": 280.1988,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "Thank you. Nothing like what",
                "page": 22,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "you’re used to working with.",
                "page": 22,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "JIMMY",
                "page": 22,
                "x0": 252,
                "x1": 287.1984,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "Still, it looks good. Shows me",
                "page": 22,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "you’re smart with a dollar.",
                "page": 22,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "TONY",
                "page": 22,
                "x0": 252,
                "x1": 280.1988,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "What brings you to Vancouver?",
                "page": 22,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "JIMMY WAH",
                "page": 22,
                "x0": 252,
                "x1": 315.19919999999996,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "Things are slow in Hong Kong. I",
                "page": 22,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "hear there is opportunity here.",
                "page": 22,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "Good tax credit. Everybody knows",
                "page": 22,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "how to mind their business.",
                "page": 22,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "TONY",
                "page": 22,
                "x0": 252,
                "x1": 280.1988,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "Let’s hope it stays that way.",
                "page": 22,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "The friendliness evaporates. Jimmy drops his cigarette to",
                "page": 22,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "the floor and steps on it and turns to face Tony. He takes",
                "page": 22,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "him by the arm and leads him away. We see the rehearsal from",
                "page": 22,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "another angle now, but still Mei Mei is there in his view.",
                "page": 22,
                "x0": 108,
                "x1": 514.1916,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 22,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 20.",
                "page": 23,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "23 CONTINUED: 23",
                "page": 23,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "JIMMY WAH",
                "page": 23,
                "x0": 252,
                "x1": 315.19919999999996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "I’m afraid you and I have an issue",
                "page": 23,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "with Mei Mei.",
                "page": 23,
                "x0": 180,
                "x1": 271.2,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "TONY",
                "page": 23,
                "x0": 252,
                "x1": 280.1988,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "What’s the problem.",
                "page": 23,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "JIMMY WAH",
                "page": 23,
                "x0": 252,
                "x1": 315.19919999999996,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "I found Mei Mei in a go-go bar when",
                "page": 23,
                "x0": 180,
                "x1": 425.1923999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "she was 16. I gave her one look and",
                "page": 23,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "I said ‘that girl could be",
                "page": 23,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "something’. So I put her under",
                "page": 23,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "contract. Modeling lessons, acting",
                "page": 23,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "lessons, English lessons - I poured",
                "page": 23,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "a lot of resources into her.",
                "page": 23,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "TONY",
                "page": 23,
                "x0": 252,
                "x1": 280.1988,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "I was under the impression it was",
                "page": 23,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "her husband Wing Lei who introduced",
                "page": 23,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "her to the business.",
                "page": 23,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "JIMMY WAH",
                "page": 23,
                "x0": 252,
                "x1": 315.19919999999996,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "You’d be wrong. That’s when she",
                "page": 23,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "faded. These gangsters like Wing",
                "page": 23,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "just want a starlet on their arm,",
                "page": 23,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "they don’t understand the hard work",
                "page": 23,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "that goes into building a career.",
                "page": 23,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "Not like we do.",
                "page": 23,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "TONY",
                "page": 23,
                "x0": 252,
                "x1": 280.1988,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Of course.",
                "page": 23,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "JIMMY WAH",
                "page": 23,
                "x0": 252,
                "x1": 315.19919999999996,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "The point is, I never agreed for",
                "page": 23,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "Mei Mei to be in this movie.",
                "page": 23,
                "x0": 180,
                "x1": 376.1919999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "TONY",
                "page": 23,
                "x0": 252,
                "x1": 280.1988,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "Mister Wah, I apologize. I was",
                "page": 23,
                "x0": 180,
                "x1": 397.1919999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "never aware of any agreement. Let",
                "page": 23,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "me take it up with Mei Mei first",
                "page": 23,
                "x0": 180,
                "x1": 404.198,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "thing in the morning.",
                "page": 23,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "Jimmy smiles.",
                "page": 23,
                "x0": 108,
                "x1": 199.19519999999994,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "JIMMY WAH",
                "page": 23,
                "x0": 252,
                "x1": 315.19919999999996,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "I remember when you were just a",
                "page": 23,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "gofer, running around set, pouring",
                "page": 23,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "tea, selling bad coke to all the",
                "page": 23,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "actors. And look at you now.",
                "page": 23,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "TONY",
                "page": 23,
                "x0": 252,
                "x1": 280.1988,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "That was a long time ago.",
                "page": 23,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 23,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Green (09/09/2016) 21.",
                "page": 24,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "23 CONTINUED: (2) 23",
                "page": 24,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "JIMMY WAH",
                "page": 24,
                "x0": 252,
                "x1": 315.19919999999996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Not that long. I also remember last",
                "page": 24,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "year when they had you locked in an",
                "page": 24,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "immigration cell for a month. Now",
                "page": 24,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "you’re out and a free man. That’s",
                "page": 24,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "another thing I like about this",
                "page": 24,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "country. There’s always room for a",
                "page": 24,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "hungry man to crawl his way up the",
                "page": 24,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "ladder.",
                "page": 24,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            }
        ],
        "scene_number": "23",
        "int_ext": "INT.",
        "location": "MOVIE SET",
        "time": "NIGHT"
    },
    {
        "text": "25 INT. PENTHOUSE - NIGHT 25",
        "page": 24,
        "lines": [
            {
                "text": "At the top of the stairs, leading down into the main club,",
                "page": 24,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 265.592,
                "y1": 277.592
            },
            {
                "text": "which hums below. GO-GO DANCERS on stage. Frank throws an",
                "page": 24,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 277.592,
                "y1": 289.592
            },
            {
                "text": "arm around Rufus as he walks him down the stairs, leaning",
                "page": 24,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 289.592,
                "y1": 301.592
            },
            {
                "text": "into his ear above the club noise. People notice Frank as he",
                "page": 24,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 301.592,
                "y1": 313.592
            },
            {
                "text": "introduces Rufus to the club -",
                "page": 24,
                "x0": 108,
                "x1": 318.1883999999999,
                "y0": 313.592,
                "y1": 325.592
            },
            {
                "text": "FRANK",
                "page": 24,
                "x0": 252,
                "x1": 287.1984,
                "y0": 337.592,
                "y1": 349.592
            },
            {
                "text": "All the players work out of here.",
                "page": 24,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 349.592,
                "y1": 361.592
            },
            {
                "text": "We supply the downtown strip.",
                "page": 24,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 361.592,
                "y1": 373.592
            },
            {
                "text": "Alonzo deals with the day to day -",
                "page": 24,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 373.592,
                "y1": 385.592
            },
            {
                "text": "ALONZO throws a look from where he kibitzes with the",
                "page": 24,
                "x0": 108,
                "x1": 472.1796000000002,
                "y0": 397.592,
                "y1": 409.592
            },
            {
                "text": "GANGSTERS sitting in the booths.",
                "page": 24,
                "x0": 108,
                "x1": 332.1875999999999,
                "y0": 409.592,
                "y1": 421.592
            },
            {
                "text": "FRANK (CONT’D)",
                "page": 24,
                "x0": 252,
                "x1": 350.1968,
                "y0": 433.592,
                "y1": 445.592
            },
            {
                "text": "You gotta just do what you do,",
                "page": 24,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 445.592,
                "y1": 457.592
            },
            {
                "text": "making sure you got the product in",
                "page": 24,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 457.592,
                "y1": 469.592
            },
            {
                "text": "stock to keep the street rocking.",
                "page": 24,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 469.592,
                "y1": 481.592
            },
            {
                "text": "Rufus and Frank have turned a few heads as they cruise the",
                "page": 24,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 493.592,
                "y1": 505.592
            },
            {
                "text": "booths along the wall -",
                "page": 24,
                "x0": 108,
                "x1": 269.1911999999999,
                "y0": 505.592,
                "y1": 517.592
            },
            {
                "text": "FRANK (CONT’D)",
                "page": 24,
                "x0": 252,
                "x1": 350.1968,
                "y0": 529.592,
                "y1": 541.592
            },
            {
                "text": "Let me introduce you to some of the",
                "page": 24,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 541.592,
                "y1": 553.592
            },
            {
                "text": "players down here -",
                "page": 24,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 553.592,
                "y1": 565.592
            },
            {
                "text": "He stops at the first booth -",
                "page": 24,
                "x0": 108,
                "x1": 311.18879999999984,
                "y0": 577.592,
                "y1": 589.592
            },
            {
                "text": "FRANK (CONT’D)",
                "page": 24,
                "x0": 252,
                "x1": 350.1968,
                "y0": 601.592,
                "y1": 613.592
            },
            {
                "text": "Jonno meet Rufus - Rufus is going",
                "page": 24,
                "x0": 180,
                "x1": 411.18919999999986,
                "y0": 613.592,
                "y1": 625.592
            },
            {
                "text": "to be helping you out now - he’s",
                "page": 24,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 625.592,
                "y1": 637.592
            },
            {
                "text": "the man, alright. And I think",
                "page": 24,
                "x0": 180,
                "x1": 390.19479999999993,
                "y0": 637.592,
                "y1": 649.592
            },
            {
                "text": "you’re going to be happy with what",
                "page": 24,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 649.592,
                "y1": 661.592
            },
            {
                "text": "he brings to the table -",
                "page": 24,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 661.592,
                "y1": 673.592
            },
            {
                "text": "(CONTINUED)",
                "page": 24,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Goldenrod (09/12/2016) 22.",
                "page": 25,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "25 CONTINUED: 25",
                "page": 25,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "JONNO",
                "page": 25,
                "x0": 252,
                "x1": 287.1984,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "What exactly are you bringing to",
                "page": 25,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "the table -",
                "page": 25,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "RUFUS",
                "page": 25,
                "x0": 252,
                "x1": 287.1984,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "How about a round of drinks for a",
                "page": 25,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "start -",
                "page": 25,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "FRANK",
                "page": 25,
                "x0": 252,
                "x1": 287.1984,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "There you go - c’mon, these other",
                "page": 25,
                "x0": 180,
                "x1": 411.19519999999994,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "fellas gotta say hello -",
                "page": 25,
                "x0": 180,
                "x1": 348.19319999999993,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "Alonzo watches from the last booth as Frank moves Rufus down",
                "page": 25,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "the room, introducing him to the group and coming forward to",
                "page": 25,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "Alonzo’s table.",
                "page": 25,
                "x0": 108,
                "x1": 213.19439999999994,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "FRANK (CONT’D)",
                "page": 25,
                "x0": 252,
                "x1": 350.1968,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "Charles, this is Rufus, he’s our",
                "page": 25,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "new man on the street here.",
                "page": 25,
                "x0": 180,
                "x1": 369.2,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "RUFUS",
                "page": 25,
                "x0": 252,
                "x1": 287.1984,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "Good to meet you -",
                "page": 25,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "Frank gives Alonzo a look to prod him.",
                "page": 25,
                "x0": 108,
                "x1": 374.1852,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "ALONZO",
                "page": 25,
                "x0": 252,
                "x1": 294.198,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "Welcome to the street.",
                "page": 25,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "RUFUS",
                "page": 25,
                "x0": 252,
                "x1": 287.1984,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "Feels like coming home.",
                "page": 25,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "FRANK",
                "page": 25,
                "x0": 252,
                "x1": 287.1984,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "Scuse me a minute.",
                "page": 25,
                "x0": 180,
                "x1": 306.19519999999994,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "He nods Alonzo aside. Rufus continues to chat with and be",
                "page": 25,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "introduced to various people as -",
                "page": 25,
                "x0": 108,
                "x1": 339.1871999999999,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "FRANK (CONT’D)",
                "page": 25,
                "x0": 252,
                "x1": 350.1968,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "You’re gonna be on your best",
                "page": 25,
                "x0": 180,
                "x1": 376.1939999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "behaviour with this guy.",
                "page": 25,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "ALONZO",
                "page": 25,
                "x0": 252,
                "x1": 294.198,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "You let him get away with taking",
                "page": 25,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "out Andre and every goof in the",
                "page": 25,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "city is gonna think they can shoot",
                "page": 25,
                "x0": 180,
                "x1": 418.1919999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "their way to the top.",
                "page": 25,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "FRANK",
                "page": 25,
                "x0": 252,
                "x1": 287.1984,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "You gotta change your attitude. As",
                "page": 25,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "long as this guy produces, we like",
                "page": 25,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "him.",
                "page": 25,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(MORE)",
                "page": 25,
                "x0": 252,
                "x1": 294.198,
                "y0": 684.5921,
                "y1": 696.5921
            },
            {
                "text": "(CONTINUED)",
                "page": 25,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 23.",
                "page": 26,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "25 CONTINUED: (2) 25",
                "page": 26,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "FRANK (CONT'D)",
                "page": 26,
                "x0": 252,
                "x1": 350.19479999999993,
                "y0": 60.591999999999985,
                "y1": 72.59199999999998
            },
            {
                "text": "We gotta make him feel like he’s",
                "page": 26,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "welcome, not like he’s gonna get a",
                "page": 26,
                "x0": 180,
                "x1": 418.198,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "knife in his neck. Lighten the",
                "page": 26,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "fuck up.",
                "page": 26,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "ALONZO",
                "page": 26,
                "x0": 252,
                "x1": 294.198,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "I got it.",
                "page": 26,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "FRANK",
                "page": 26,
                "x0": 252,
                "x1": 287.1984,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "He’ll get what’s coming to him, but",
                "page": 26,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "let’s get the money out of him",
                "page": 26,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "before we throw him away.",
                "page": 26,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "ALONZO",
                "page": 26,
                "x0": 252,
                "x1": 294.198,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "You gotta promise me I get first",
                "page": 26,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "shot at him when time comes.",
                "page": 26,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "FRANK",
                "page": 26,
                "x0": 252,
                "x1": 287.1984,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "Fair enough.",
                "page": 26,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "ALONZO",
                "page": 26,
                "x0": 252,
                "x1": 294.198,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "When your partner gets dusted",
                "page": 26,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "you’re supposed to do something",
                "page": 26,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "about it.",
                "page": 26,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "FRANK",
                "page": 26,
                "x0": 252,
                "x1": 287.1984,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "You’ll get your shot.",
                "page": 26,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "Frank drifts away to take Rufus by the elbow -",
                "page": 26,
                "x0": 108,
                "x1": 430.18200000000013,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "FRANK (CONT’D)",
                "page": 26,
                "x0": 252,
                "x1": 350.1968,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "C’mon, there’s a couple guys you",
                "page": 26,
                "x0": 180,
                "x1": 404.18959999999987,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "gotta say hello. They got half a",
                "page": 26,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "dozen clubs on the strip -",
                "page": 26,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "He wades Rufus into the tables.",
                "page": 26,
                "x0": 108,
                "x1": 325.1879999999999,
                "y0": 492.592,
                "y1": 504.592
            }
        ],
        "scene_number": "25",
        "int_ext": "INT.",
        "location": "PENTHOUSE",
        "time": "NIGHT"
    },
    {
        "text": "28 INT. EMPEROR’S QUARTERS - NIGHT 28",
        "page": 26,
        "lines": [
            {
                "text": "FAN FAN is flung into the Emperor’s room.",
                "page": 26,
                "x0": 108,
                "x1": 395.18679999999995,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "EMPEROR",
                "page": 26,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "Do you know where my son is hiding.",
                "page": 26,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "FAN FAN",
                "page": 26,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "Why would you ask me.",
                "page": 26,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 26,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 24.",
                "page": 27,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "28 CONTINUED: 28",
                "page": 27,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "EMPEROR",
                "page": 27,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "I see how you look at him, how you",
                "page": 27,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "talk about him.",
                "page": 27,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "FAN FAN",
                "page": 27,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "He is your son. I care for him.",
                "page": 27,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "EMPEROR",
                "page": 27,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Do you know where he is hiding.",
                "page": 27,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "FAN FAN",
                "page": 27,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "No.",
                "page": 27,
                "x0": 180,
                "x1": 201.1992,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "EMPEROR",
                "page": 27,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "Why does my own son betray me.",
                "page": 27,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "FAN FAN",
                "page": 27,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "Because you ignore him since the",
                "page": 27,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "day he was born and do not let him",
                "page": 27,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "play in the court where he might",
                "page": 27,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "learn at the knee of scholars, but",
                "page": 27,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "instead you let him wander in the",
                "page": 27,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "city amongst pimps, murderers and",
                "page": 27,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "thieves -",
                "page": 27,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "The Emperor threatens to strike her. She blocks his hand.",
                "page": 27,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "FAN FAN (CONT’D)",
                "page": 27,
                "x0": 252,
                "x1": 364.1968,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "Do not strike me. I warn you.",
                "page": 27,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "EMPEROR",
                "page": 27,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Do you know where he is hiding.",
                "page": 27,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "FAN FAN",
                "page": 27,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "I know nothing. I don’t know what",
                "page": 27,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "he was up to and I don’t know where",
                "page": 27,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "he is. He is a stupid boy, lazy",
                "page": 27,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "and foolish. Why would I pay any",
                "page": 27,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "attention to him.",
                "page": 27,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "EMPEROR",
                "page": 27,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "If you lie to me I will sink you in",
                "page": 27,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "the ocean alongside the opium.",
                "page": 27,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "FAN FAN",
                "page": 27,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "If you throw me in the ocean, no",
                "page": 27,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "one will blink an eye. But if you",
                "page": 27,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "sink the opium - a provocation like",
                "page": 27,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "that will cause the British to",
                "page": 27,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "attack us up and down the coast and",
                "page": 27,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "send their soldiers to occupy our",
                "page": 27,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "towns.",
                "page": 27,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(MORE)",
                "page": 27,
                "x0": 252,
                "x1": 294.198,
                "y0": 696.5921,
                "y1": 708.5921
            },
            {
                "text": "(CONTINUED)",
                "page": 27,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 25.",
                "page": 28,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "28 CONTINUED: (2) 28",
                "page": 28,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "FAN FAN (CONT'D)",
                "page": 28,
                "x0": 252,
                "x1": 364.1939999999999,
                "y0": 60.591999999999985,
                "y1": 72.59199999999998
            },
            {
                "text": "They will swarm over us like",
                "page": 28,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "locusts. Then what will you do.",
                "page": 28,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "EMPEROR",
                "page": 28,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "I will destroy their opium and hang",
                "page": 28,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "my traitorous son from the gallows.",
                "page": 28,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            }
        ],
        "scene_number": "28",
        "int_ext": "INT.",
        "location": "EMPEROR’S QUARTERS",
        "time": "NIGHT"
    },
    {
        "text": "28a INT. PENTHOUSE - OFFICE LOBBY - NIGHT 28a",
        "page": 28,
        "lines": [
            {
                "text": "Rufus now has drink in hand as Frank opens the door to his",
                "page": 28,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "office -",
                "page": 28,
                "x0": 108,
                "x1": 164.19719999999998,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "FRANK",
                "page": 28,
                "x0": 252,
                "x1": 287.1984,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "We picked this place up twenty",
                "page": 28,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "years ago, people thought it was a",
                "page": 28,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "money pit, now it’s worth ten times",
                "page": 28,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "what we paid, maybe more. Come on",
                "page": 28,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "in. It’s all in the price of the",
                "page": 28,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "dirt. You own any real estate?",
                "page": 28,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "He closes the door behind them.",
                "page": 28,
                "x0": 108,
                "x1": 325.1879999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "RUFUS",
                "page": 28,
                "x0": 252,
                "x1": 287.1984,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "Just the sand in my shoes.",
                "page": 28,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "Rufus enters after Frank.",
                "page": 28,
                "x0": 108,
                "x1": 283.1903999999999,
                "y0": 384.592,
                "y1": 396.592
            }
        ],
        "scene_number": "28a",
        "int_ext": "INT.",
        "location": "PENTHOUSE - OFFICE LOBBY",
        "time": "NIGHT"
    },
    {
        "text": "28b INT. CLUB OFFICE - CONTINUING - NIGHT 28b",
        "page": 28,
        "lines": [
            {
                "text": "Frank gets a stogie going.",
                "page": 28,
                "x0": 108,
                "x1": 290.1899999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "FRANK",
                "page": 28,
                "x0": 252,
                "x1": 287.1984,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "That’ll change - You’ll get",
                "page": 28,
                "x0": 180,
                "x1": 369.1923999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "yourself a piece if everything goes",
                "page": 28,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "like you say it can.",
                "page": 28,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "RUFUS",
                "page": 28,
                "x0": 252,
                "x1": 287.1984,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "I like the sound of that.",
                "page": 28,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "Frank sits on the edge of the desk.",
                "page": 28,
                "x0": 108,
                "x1": 353.18639999999994,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "FRANK",
                "page": 28,
                "x0": 252,
                "x1": 287.1984,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "How good is this dope connection",
                "page": 28,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "and how long can he keep it coming.",
                "page": 28,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "RUFUS",
                "page": 28,
                "x0": 252,
                "x1": 287.1984,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "Hard to say.",
                "page": 28,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "FRANK",
                "page": 28,
                "x0": 252,
                "x1": 287.1984,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "Give it a shot.",
                "page": 28,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 28,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Goldenrod (09/12/2016) 26.",
                "page": 29,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "28b CONTINUED: 28b",
                "page": 29,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "RUFUS",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "There’s a few factors involved we",
                "page": 29,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "have no control over.",
                "page": 29,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "FRANK",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "For instance what.",
                "page": 29,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "RUFUS",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Like pricing. It depends on the",
                "page": 29,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "harvest. If the poppies had a good",
                "page": 29,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "year, we have a good year.",
                "page": 29,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "FRANK",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "This year, how we doing.",
                "page": 29,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "RUFUS",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "It’s a good year.",
                "page": 29,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "FRANK",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "You got an idea what kind of volume",
                "page": 29,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "you can deliver then we can discuss",
                "page": 29,
                "x0": 180,
                "x1": 425.1879999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "the price.",
                "page": 29,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "RUFUS",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "Put a number on it.",
                "page": 29,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "FRANK",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "Let’s say - ten kilos a month.",
                "page": 29,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "RUFUS",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Ten kilos a week.",
                "page": 29,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "FRANK",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "Ten a month.",
                "page": 29,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "RUFUS",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "I thought you said a week. I musta",
                "page": 29,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "heard what I wanted to hear.",
                "page": 29,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "Frank laughs.",
                "page": 29,
                "x0": 108,
                "x1": 199.19519999999994,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "FRANK",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "Let’s start with what I said and",
                "page": 29,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "see how that goes.",
                "page": 29,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "RUFUS",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "I’ll confirm that with the",
                "page": 29,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "fisherman.",
                "page": 29,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "FRANK",
                "page": 29,
                "x0": 252,
                "x1": 287.1984,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "Good. We’ll settle on a price, and",
                "page": 29,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "we’ll do the deal. On to this",
                "page": 29,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "other business.",
                "page": 29,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(MORE)",
                "page": 29,
                "x0": 252,
                "x1": 294.198,
                "y0": 708.5921,
                "y1": 720.5921
            },
            {
                "text": "(CONTINUED)",
                "page": 29,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 27.",
                "page": 30,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "28b CONTINUED: (2) 28b",
                "page": 30,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "FRANK (CONT'D)",
                "page": 30,
                "x0": 252,
                "x1": 350.19479999999993,
                "y0": 60.591999999999985,
                "y1": 72.59199999999998
            },
            {
                "text": "Alonzo’s a bit touchy about you",
                "page": 30,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "taking a piece of the club, but",
                "page": 30,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "he’s gonna get over it. So don’t",
                "page": 30,
                "x0": 180,
                "x1": 411.1911999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "throw any fuel on the fire.",
                "page": 30,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "RUFUS",
                "page": 30,
                "x0": 252,
                "x1": 287.1984,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "You’ll get no trouble outta me. I",
                "page": 30,
                "x0": 180,
                "x1": 418.19759999999997,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "want to make this work.",
                "page": 30,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "FRANK",
                "page": 30,
                "x0": 252,
                "x1": 287.1984,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "Alonzo’s not a bad guy when you get",
                "page": 30,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "to know him a little. Give it",
                "page": 30,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "time, you’ll see he’s pretty",
                "page": 30,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "useful.",
                "page": 30,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "RUFUS",
                "page": 30,
                "x0": 252,
                "x1": 287.1984,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "Sounds good. I’m gonna jump - see",
                "page": 30,
                "x0": 180,
                "x1": 411.19599999999997,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "if I can get wheels under this",
                "page": 30,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "deal.",
                "page": 30,
                "x0": 180,
                "x1": 215.1984,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "FRANK",
                "page": 30,
                "x0": 252,
                "x1": 287.1984,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "You know how to find me.",
                "page": 30,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 324.592,
                "y1": 336.592
            }
        ],
        "scene_number": "28b",
        "int_ext": "INT.",
        "location": "CLUB OFFICE",
        "time": "NIGHT"
    },
    {
        "text": "28c INT. PENTHOUSE - NIGHT 28c",
        "page": 30,
        "lines": [
            {
                "text": "Rufus exits.",
                "page": 30,
                "x0": 108,
                "x1": 192.19559999999996,
                "y0": 384.592,
                "y1": 396.592
            }
        ],
        "scene_number": "28c",
        "int_ext": "INT.",
        "location": "PENTHOUSE",
        "time": "NIGHT"
    },
    {
        "text": "29 INT. MOVIE SET - NIGHT 29",
        "page": 30,
        "lines": [
            {
                "text": "TONY chats with the CONCUBINE EXTRAS as Rufus approaches.",
                "page": 30,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "RUFUS",
                "page": 30,
                "x0": 252,
                "x1": 287.1984,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "I have a buyer for ten kilos, cash",
                "page": 30,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "up front. And the same thing every",
                "page": 30,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "week for a year.",
                "page": 30,
                "x0": 180,
                "x1": 292.1939999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "TONY",
                "page": 30,
                "x0": 252,
                "x1": 280.1988,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Every week for a year. This is",
                "page": 30,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "real.",
                "page": 30,
                "x0": 180,
                "x1": 215.1984,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "RUFUS",
                "page": 30,
                "x0": 252,
                "x1": 287.1984,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "Really real.",
                "page": 30,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "TONY",
                "page": 30,
                "x0": 252,
                "x1": 280.1988,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "You want to tell me who it is.",
                "page": 30,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "RUFUS",
                "page": 30,
                "x0": 252,
                "x1": 287.1984,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "Nope. And he’s not gonna know who",
                "page": 30,
                "x0": 180,
                "x1": 418.1968,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "you are either.",
                "page": 30,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 30,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 28.",
                "page": 31,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "29 CONTINUED: 29",
                "page": 31,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "TONY",
                "page": 31,
                "x0": 252,
                "x1": 280.1988,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "This is good. This is good. Thank",
                "page": 31,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "you.",
                "page": 31,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "RUFUS",
                "page": 31,
                "x0": 252,
                "x1": 287.1984,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "Yeah. Let me know when we can make",
                "page": 31,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "the exchange. Is Mei Mei around?",
                "page": 31,
                "x0": 180,
                "x1": 411.1972,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "TONY",
                "page": 31,
                "x0": 252,
                "x1": 280.1988,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "Somewhere, yeah. She’s had a big",
                "page": 31,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "day today.",
                "page": 31,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "RUFUS",
                "page": 31,
                "x0": 252,
                "x1": 287.1984,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "There she is.",
                "page": 31,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "Rufus sees Mei Mei and starts towards her. Tony stops him.",
                "page": 31,
                "x0": 108,
                "x1": 521.1840000000001,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "TONY",
                "page": 31,
                "x0": 252,
                "x1": 280.1988,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "Wait. We may have a real movie",
                "page": 31,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "here. It could make some real",
                "page": 31,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "money. You could get a piece of",
                "page": 31,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "that. Or you could do something to",
                "page": 31,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "fuck it up.",
                "page": 31,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "RUFUS",
                "page": 31,
                "x0": 252,
                "x1": 287.1984,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "Like what.",
                "page": 31,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "TONY",
                "page": 31,
                "x0": 252,
                "x1": 280.1988,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "Like letting Mei Mei’s husband Wing",
                "page": 31,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "Lei hear you’re getting tight with",
                "page": 31,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "his wife and him deciding to feed",
                "page": 31,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "you to the sharks in the South",
                "page": 31,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "China sea for doing it and me for",
                "page": 31,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "letting it happen.",
                "page": 31,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "RUFUS",
                "page": 31,
                "x0": 252,
                "x1": 287.1984,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "I’m just gonna say hello and let",
                "page": 31,
                "x0": 180,
                "x1": 404.19319999999993,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "her know I’m bringing in some of",
                "page": 31,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "her cash.",
                "page": 31,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "TONY",
                "page": 31,
                "x0": 252,
                "x1": 280.1988,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "It’s Wing’s cash, let’s not kid",
                "page": 31,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "ourselves. And we gotta finish",
                "page": 31,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "this movie before he finds out",
                "page": 31,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "we’re spending it as fast as it",
                "page": 31,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "comes in. So instead of chasing",
                "page": 31,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "Mei Mei, go make the deal because",
                "page": 31,
                "x0": 180,
                "x1": 411.1899999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "you won’t even have a dick to wag",
                "page": 31,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "if Wing gets wise.",
                "page": 31,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "RUFUS",
                "page": 31,
                "x0": 252,
                "x1": 287.1984,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "Tell Mei Mei I was here.",
                "page": 31,
                "x0": 180,
                "x1": 348.19559999999996,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 31,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 29.",
                "page": 32,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "29 CONTINUED: (2) 29",
                "page": 32,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "He walks off -",
                "page": 32,
                "x0": 108,
                "x1": 206.19479999999993,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            }
        ],
        "scene_number": "29",
        "int_ext": "INT.",
        "location": "MOVIE SET",
        "time": "NIGHT"
    },
    {
        "text": "30 INT. NORMAN’S APARTMENT - DAWN 30",
        "page": 32,
        "lines": [
            {
                "text": "Norman stands in the middle of the room. Rocking back and",
                "page": 32,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "forth ever so slightly. Staring at the wall - which is now",
                "page": 32,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "overloaded with details, photos, words, thread.",
                "page": 32,
                "x0": 108,
                "x1": 437.1816000000001,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "A noise. FOOTSTEPS. Norman looks up. He can hear light",
                "page": 32,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "footsteps coming from the roof. He follows them with his",
                "page": 32,
                "x0": 108,
                "x1": 507.17760000000027,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "eyes across the ceiling.",
                "page": 32,
                "x0": 108,
                "x1": 276.1907999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "Norman tiptoes to the bed. He reaches under his side of the",
                "page": 32,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "bed. Finds a LARGE MACHETE.",
                "page": 32,
                "x0": 108,
                "x1": 304.18919999999986,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            }
        ],
        "scene_number": "30",
        "int_ext": "INT.",
        "location": "NORMAN’S APARTMENT",
        "time": "DAWN"
    },
    {
        "text": "31 INT. NORMAN’S APARTMENT - HALLWAY - DAWN 31",
        "page": 32,
        "lines": [
            {
                "text": "Machete in hand, Norman steps out into the hallway. Finds",
                "page": 32,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "his way to the stairwell.",
                "page": 32,
                "x0": 108,
                "x1": 283.1903999999999,
                "y0": 312.592,
                "y1": 324.592
            }
        ],
        "scene_number": "31",
        "int_ext": "INT.",
        "location": "NORMAN’S APARTMENT - HALLWAY",
        "time": "DAWN"
    },
    {
        "text": "32 INT. NORMAN’S APARTMENT - STAIRWELL - DAWN 32",
        "page": 32,
        "lines": [
            {
                "text": "He moves up the stairs, heading to the -",
                "page": 32,
                "x0": 108,
                "x1": 388.18440000000004,
                "y0": 372.592,
                "y1": 384.592
            }
        ],
        "scene_number": "32",
        "int_ext": "INT.",
        "location": "NORMAN’S APARTMENT - STAIRWELL",
        "time": "DAWN"
    },
    {
        "text": "33 EXT. NORMAN’S APARTMENT - ROOFTOP - DAWN 33",
        "page": 32,
        "lines": [
            {
                "text": "The sun is just starting to come up.",
                "page": 32,
                "x0": 108,
                "x1": 360.186,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Norman gently cracks the door. Looks around. Nobody around.",
                "page": 32,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "Sees a piece of Graffiti on the wall. In large letters, it",
                "page": 32,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "reads: “EVERYTHING IS FINE”.",
                "page": 32,
                "x0": 108,
                "x1": 304.18919999999986,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "He just stares at it. Everything is certainly not fine.",
                "page": 32,
                "x0": 108,
                "x1": 500.1780000000002,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "Here he is on the roof in his underwear, clutching a machete.",
                "page": 32,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 528.592,
                "y1": 540.592
            }
        ],
        "scene_number": "33",
        "int_ext": "EXT.",
        "location": "NORMAN’S APARTMENT - ROOFTOP",
        "time": "DAWN"
    },
    {
        "text": "34 EXT. CAFE - EARLY - DAY 34",
        "page": 32,
        "lines": [
            {
                "text": "Wolfgang at an outside table in the quiet hours of the",
                "page": 32,
                "x0": 108,
                "x1": 486.17880000000025,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "morning, reading his paper, sips his coffee. Norman arrives",
                "page": 32,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "and joins him.",
                "page": 32,
                "x0": 108,
                "x1": 206.19479999999993,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "WOLFGANG",
                "page": 32,
                "x0": 252,
                "x1": 308.1972,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "You alright. You’re looking a",
                "page": 32,
                "x0": 180,
                "x1": 390.1927999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "little ragged -",
                "page": 32,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "(CONTINUED)",
                "page": 32,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Goldenrod (09/12/2016) 30.",
                "page": 33,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "34 CONTINUED: 34",
                "page": 33,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "NORMAN",
                "page": 33,
                "x0": 252,
                "x1": 294.198,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "I can’t get Sonya out of my head.",
                "page": 33,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "I’ve been up all night.",
                "page": 33,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "Now he spots CANDY (the Escort) approaching and waves to her.",
                "page": 33,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "NORMAN (CONT’D)",
                "page": 33,
                "x0": 252,
                "x1": 357.1968,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "Candy. Hello.",
                "page": 33,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Candy spots the table and approaches.",
                "page": 33,
                "x0": 108,
                "x1": 367.18559999999997,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 33,
                "x0": 252,
                "x1": 308.1972,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "What the fuck is she doing here.",
                "page": 33,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 33,
                "x0": 252,
                "x1": 294.198,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "That’s the escort we saw at Michael",
                "page": 33,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "Gary’s -",
                "page": 33,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "WOLFGANG",
                "page": 33,
                "x0": 252,
                "x1": 308.1972,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "I’m aware of who she is, what the",
                "page": 33,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "fuck are you doing -",
                "page": 33,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "NORMAN",
                "page": 33,
                "x0": 252,
                "x1": 294.198,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "You don’t believe in scopolamine -",
                "page": 33,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "play along. We’re insurance men -",
                "page": 33,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "CANDY",
                "page": 33,
                "x0": 252,
                "x1": 287.1984,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "Hello -",
                "page": 33,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "NORMAN",
                "page": 33,
                "x0": 252,
                "x1": 294.198,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Hello Candy - I’m Norman and this",
                "page": 33,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "is Rupert -",
                "page": 33,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "CANDY",
                "page": 33,
                "x0": 252,
                "x1": 287.1984,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "Nice to meet you both -",
                "page": 33,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "NORMAN",
                "page": 33,
                "x0": 252,
                "x1": 294.198,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "Can we order you something?",
                "page": 33,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "CANDY",
                "page": 33,
                "x0": 252,
                "x1": 287.1984,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "Vodka and orange. I know it’s",
                "page": 33,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "early but morning is my evening -",
                "page": 33,
                "x0": 180,
                "x1": 411.19159999999994,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "Norman leans to get the Waitress’ attention -",
                "page": 33,
                "x0": 108,
                "x1": 423.1824000000001,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "NORMAN",
                "page": 33,
                "x0": 252,
                "x1": 294.198,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "I’m the same way.",
                "page": 33,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "(to Waitress)",
                "page": 33,
                "x0": 207,
                "x1": 298.19519999999994,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "Two vodka and orange and for you?",
                "page": 33,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "WOLFGANG",
                "page": 33,
                "x0": 252,
                "x1": 308.1972,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "Black coffee, side of toast.",
                "page": 33,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 33,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 31.",
                "page": 34,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "34 CONTINUED: (2) 34",
                "page": 34,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "CANDY",
                "page": 34,
                "x0": 252,
                "x1": 287.1984,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "My manager said this was about an",
                "page": 34,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "insurance claim of some sort -",
                "page": 34,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 34,
                "x0": 252,
                "x1": 294.198,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "Right.",
                "page": 34,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "CANDY",
                "page": 34,
                "x0": 252,
                "x1": 287.1984,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "And you’d be paying for the",
                "page": 34,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "interview.",
                "page": 34,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 34,
                "x0": 252,
                "x1": 294.198,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "Whatever your going rate might be.",
                "page": 34,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "CANDY",
                "page": 34,
                "x0": 252,
                "x1": 287.1984,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "Great. Go ahead. Ask away.",
                "page": 34,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "NORMAN",
                "page": 34,
                "x0": 252,
                "x1": 294.198,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "In your experience as an escort",
                "page": 34,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "have you ever had a client",
                "page": 34,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "encourage you to take drugs during",
                "page": 34,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "the date?",
                "page": 34,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "CANDY",
                "page": 34,
                "x0": 252,
                "x1": 287.1984,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "You’re kidding. Of course, all the",
                "page": 34,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "time. They want you to get high",
                "page": 34,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "with them - coke, weed, smack,",
                "page": 34,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "ecstasy, acid, whatever makes the",
                "page": 34,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "party rock.",
                "page": 34,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "NORMAN",
                "page": 34,
                "x0": 252,
                "x1": 294.198,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Did you ever come across the drug",
                "page": 34,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "called Fantanamine, also known as",
                "page": 34,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "scopolamine?",
                "page": 34,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "CANDY",
                "page": 34,
                "x0": 252,
                "x1": 287.1984,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "Don’t think so. What does it do.",
                "page": 34,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "NORMAN",
                "page": 34,
                "x0": 252,
                "x1": 294.198,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Rupert?",
                "page": 34,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "WOLFGANG",
                "page": 34,
                "x0": 252,
                "x1": 308.1972,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "It’s an amnesia inducing",
                "page": 34,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "hallucinogen.",
                "page": 34,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "CANDY",
                "page": 34,
                "x0": 252,
                "x1": 287.1984,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "Like a date rape drug.",
                "page": 34,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "WOLFGANG",
                "page": 34,
                "x0": 252,
                "x1": 308.1972,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "That’s right.",
                "page": 34,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "(CONTINUED)",
                "page": 34,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 32.",
                "page": 35,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "34 CONTINUED: (3) 34",
                "page": 35,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "NORMAN",
                "page": 35,
                "x0": 252,
                "x1": 294.198,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "It makes you very compliant,",
                "page": 35,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "cooperative, and you don’t remember",
                "page": 35,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "a thing afterwards, including all",
                "page": 35,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "the charges you’ve put on your",
                "page": 35,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "credit cards -",
                "page": 35,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "CANDY",
                "page": 35,
                "x0": 252,
                "x1": 287.1984,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Okay, I think you’re talking about",
                "page": 35,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "what they call Devil’s Breath.",
                "page": 35,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 35,
                "x0": 252,
                "x1": 294.198,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "That’s the one.",
                "page": 35,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "CANDY",
                "page": 35,
                "x0": 252,
                "x1": 287.1984,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "I’ve heard of it. Some girls might",
                "page": 35,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "slip it in a date’s drink, keep him",
                "page": 35,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "going for days and days. A three",
                "page": 35,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "hundred dollar date turns into a",
                "page": 35,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "thirty thousand dollar date. I’ve",
                "page": 35,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "never used it myself.",
                "page": 35,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "NORMAN",
                "page": 35,
                "x0": 252,
                "x1": 294.198,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "Never used it yourself.",
                "page": 35,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "CANDY",
                "page": 35,
                "x0": 252,
                "x1": 287.1984,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "No.",
                "page": 35,
                "x0": 180,
                "x1": 201.1992,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "The drinks arrive. Norman waits for those to settle, then",
                "page": 35,
                "x0": 108,
                "x1": 514.1772000000003,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "Wolfgang pulls out his phone.",
                "page": 35,
                "x0": 108,
                "x1": 311.18879999999984,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "WOLFGANG",
                "page": 35,
                "x0": 252,
                "x1": 308.1972,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "Do you recognize this man?",
                "page": 35,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "He shows her a photo of Michael Gary on his phone.",
                "page": 35,
                "x0": 108,
                "x1": 458.1804000000002,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "CANDY",
                "page": 35,
                "x0": 252,
                "x1": 287.1984,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "No. Who is he.",
                "page": 35,
                "x0": 180,
                "x1": 285.1943999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "WOLFGANG",
                "page": 35,
                "x0": 252,
                "x1": 308.1972,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "He’s not one of your clients?",
                "page": 35,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "CANDY",
                "page": 35,
                "x0": 252,
                "x1": 287.1984,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "No. Good looking guy. I’d",
                "page": 35,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "remember.",
                "page": 35,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 600.592,
                "y1": 612.592
            }
        ],
        "scene_number": "34",
        "int_ext": "EXT.",
        "location": "CAFE",
        "time": "DAY"
    },
    {
        "text": "35 EXT. CAFE - STREET - DAY 35",
        "page": 35,
        "lines": [
            {
                "text": "As CANDY walks off, Wolfgang and Norman pay the bill and exit",
                "page": 35,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "the terrace.",
                "page": 35,
                "x0": 108,
                "x1": 192.19559999999996,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 35,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 33.",
                "page": 36,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "35 CONTINUED: 35",
                "page": 36,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "NORMAN",
                "page": 36,
                "x0": 252,
                "x1": 294.198,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "She doesn’t recognize Gary, and she",
                "page": 36,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "doesn’t remember being in his",
                "page": 36,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "apartment, shooting an empty gun,",
                "page": 36,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "or anything else that went on in",
                "page": 36,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "there. What does that tell you.",
                "page": 36,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 36,
                "x0": 252,
                "x1": 308.1972,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "She’s being discreet, protecting a",
                "page": 36,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "client.",
                "page": 36,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "NORMAN",
                "page": 36,
                "x0": 252,
                "x1": 294.198,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "Please, we know this stuff works.",
                "page": 36,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "Since a thousand years ago or more.",
                "page": 36,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "Scopolamine is the original zombie",
                "page": 36,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "drug. Gary used it on the security",
                "page": 36,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "guard, he used it on Candy.",
                "page": 36,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "WOLFGANG",
                "page": 36,
                "x0": 252,
                "x1": 308.1972,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "You’re speculating. We don’t know",
                "page": 36,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "that.",
                "page": 36,
                "x0": 180,
                "x1": 215.1984,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "NORMAN",
                "page": 36,
                "x0": 252,
                "x1": 294.198,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "I fucking well do know it. The",
                "page": 36,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "same as I know he used it on Sonya",
                "page": 36,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "and maybe even Mustapha -",
                "page": 36,
                "x0": 180,
                "x1": 355.1996,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "Wolfgang says nothing.",
                "page": 36,
                "x0": 108,
                "x1": 262.19159999999994,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "NORMAN (CONT’D)",
                "page": 36,
                "x0": 252,
                "x1": 357.1968,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Shall we just sit back and let him",
                "page": 36,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "perform his experiments on someone",
                "page": 36,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "else, maybe assassinate someone.",
                "page": 36,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "What more do we really need to",
                "page": 36,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "know.",
                "page": 36,
                "x0": 180,
                "x1": 215.1984,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "WOLFGANG",
                "page": 36,
                "x0": 252,
                "x1": 308.1972,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "There’s a right way and a wrong way",
                "page": 36,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "to go about taking this guy down.",
                "page": 36,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "NORMAN",
                "page": 36,
                "x0": 252,
                "x1": 294.198,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "The right way is to eliminate the",
                "page": 36,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "fuck right now before he does any",
                "page": 36,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "more damage.",
                "page": 36,
                "x0": 180,
                "x1": 264.19559999999996,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "WOLFGANG",
                "page": 36,
                "x0": 252,
                "x1": 308.1972,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "Okay, this is where we part",
                "page": 36,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "company. I’ve got some business to",
                "page": 36,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "take care of at the university.",
                "page": 36,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "You should try and get some sleep.",
                "page": 36,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "I’ll be in touch later.",
                "page": 36,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 36,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Green (09/09/2016) 34.",
                "page": 37,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "35 CONTINUED: (2) 35",
                "page": 37,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "He walks off. Norman turns on his heel and walks the other",
                "page": 37,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "way.",
                "page": 37,
                "x0": 108,
                "x1": 136.19879999999998,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            }
        ],
        "scene_number": "35",
        "int_ext": "EXT.",
        "location": "CAFE - STREET",
        "time": "DAY"
    },
    {
        "text": "36 INT. AL’S CAR - DAY 36",
        "page": 37,
        "lines": [
            {
                "text": "Al checks the Safe House live feed on his laptop.",
                "page": 37,
                "x0": 108,
                "x1": 451.1943999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "ON THE MONITOR: He checks all the angles. The place seems to",
                "page": 37,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "be empty.",
                "page": 37,
                "x0": 108,
                "x1": 171.19679999999997,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "Al scopes out the exterior. Seems quiet.",
                "page": 37,
                "x0": 108,
                "x1": 395.184,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            }
        ],
        "scene_number": "36",
        "int_ext": "INT.",
        "location": "AL’S CAR",
        "time": "DAY"
    },
    {
        "text": "37 EXT. SAFE HOUSE - AL’S CAR - DAY 37",
        "page": 37,
        "lines": [
            {
                "text": "He climbs out of his vehicle and makes his way into the",
                "page": 37,
                "x0": 108,
                "x1": 493.17840000000024,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "building, carrying a small bag of tools -",
                "page": 37,
                "x0": 108,
                "x1": 395.184,
                "y0": 276.592,
                "y1": 288.592
            }
        ],
        "scene_number": "37",
        "int_ext": "EXT.",
        "location": "SAFE HOUSE - AL’S CAR",
        "time": "DAY"
    },
    {
        "text": "38 INT. SAFE HOUSE - OUTER CORRIDOR - DAY 38",
        "page": 37,
        "lines": [
            {
                "text": "Al approaches the Safe House door. Keys himself in.",
                "page": 37,
                "x0": 108,
                "x1": 472.1796000000002,
                "y0": 336.592,
                "y1": 348.592
            }
        ],
        "scene_number": "38",
        "int_ext": "INT.",
        "location": "SAFE HOUSE - OUTER CORRIDOR",
        "time": "DAY"
    },
    {
        "text": "39 INT. SAFE HOUSE - BEDROOM - DAY 39",
        "page": 37,
        "lines": [
            {
                "text": "Al quickly peeks down the hall, then scurries to retrieve the",
                "page": 37,
                "x0": 108,
                "x1": 535.1760000000004,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "camera behind the thermostat.",
                "page": 37,
                "x0": 108,
                "x1": 311.18879999999984,
                "y0": 408.592,
                "y1": 420.592
            }
        ],
        "scene_number": "39",
        "int_ext": "INT.",
        "location": "SAFE HOUSE - BEDROOM",
        "time": "DAY"
    },
    {
        "text": "40 INT. SAFE HOUSE - MAIN ROOM - DAY 40",
        "page": 37,
        "lines": [
            {
                "text": "Working quickly, Al retrieves another camera. And another.",
                "page": 37,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "He pulls the last camera -",
                "page": 37,
                "x0": 108,
                "x1": 290.1903999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "Al slips out the door.",
                "page": 37,
                "x0": 108,
                "x1": 262.2,
                "y0": 504.592,
                "y1": 516.592
            }
        ],
        "scene_number": "40",
        "int_ext": "INT.",
        "location": "SAFE HOUSE - MAIN ROOM",
        "time": "DAY"
    },
    {
        "text": "41 EXT. SAFE HOUSE - DAY 41",
        "page": 37,
        "lines": [
            {
                "text": "Al scurries back to his waiting vehicle, carrying his bag of",
                "page": 37,
                "x0": 108,
                "x1": 528.1764000000003,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "gear.",
                "page": 37,
                "x0": 108,
                "x1": 143.1984,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "His phone rings.",
                "page": 37,
                "x0": 108,
                "x1": 220.19399999999993,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "AL",
                "page": 37,
                "x0": 252,
                "x1": 266.1996,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "Hello.",
                "page": 37,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 636.592,
                "y1": 648.592
            }
        ],
        "scene_number": "41",
        "int_ext": "EXT.",
        "location": "SAFE HOUSE",
        "time": "DAY"
    },
    {
        "text": "42a INT. CHARLIE’S HOTEL SUITE - DAY 42a",
        "page": 38,
        "lines": [
            {
                "text": "Al moves into the room.",
                "page": 38,
                "x0": 108,
                "x1": 269.1984,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "CORMACK",
                "page": 38,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "Thanks for coming on short notice.",
                "page": 38,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "AL",
                "page": 38,
                "x0": 252,
                "x1": 266.1996,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Not at all.",
                "page": 38,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "CORMACK",
                "page": 38,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "Let’s be blunt, which way are you",
                "page": 38,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "leaning and what will it take to",
                "page": 38,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "put you over the edge.",
                "page": 38,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "AL",
                "page": 38,
                "x0": 252,
                "x1": 266.1996,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "Pending details, I’m accepting your",
                "page": 38,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "offer.",
                "page": 38,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "CORMACK",
                "page": 38,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "Wonderful. I’m pleased. So, first",
                "page": 38,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "order of business - your",
                "page": 38,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "priorities, what will they be.",
                "page": 38,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "AL",
                "page": 38,
                "x0": 252,
                "x1": 266.1996,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "I think a bit of housecleaning off",
                "page": 38,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "the top. Bring in some of my own",
                "page": 38,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "people I suppose -",
                "page": 38,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "CORMACK",
                "page": 38,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Of course. May I get you a drink.",
                "page": 38,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "He’s going to get one for himself anyway.",
                "page": 38,
                "x0": 108,
                "x1": 395.184,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "AL",
                "page": 38,
                "x0": 252,
                "x1": 266.1996,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "No, I’ll pass. You have something",
                "page": 38,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "on your mind.",
                "page": 38,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "CORMACK",
                "page": 38,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Yes. Mr. Sproule was handling a",
                "page": 38,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "bit of business for me that I’d",
                "page": 38,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "like to be sure has been cleaned",
                "page": 38,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "up.",
                "page": 38,
                "x0": 180,
                "x1": 201.1992,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "AL",
                "page": 38,
                "x0": 252,
                "x1": 266.1996,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "I see.",
                "page": 38,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "Cormack returns to sit opposite Al.",
                "page": 38,
                "x0": 108,
                "x1": 353.18639999999994,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "CORMACK",
                "page": 38,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "I’m sure you’re aware of the",
                "page": 38,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "affair. Two years ago. A bomb at",
                "page": 38,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "the baseball stadium.",
                "page": 38,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 38,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Yellow (09/07/2016) 36.",
                "page": 39,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "42a CONTINUED: 42a",
                "page": 39,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "AL",
                "page": 39,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Of course.",
                "page": 39,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "CORMACK",
                "page": 39,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "The Quiller Inquiry, of which I am",
                "page": 39,
                "x0": 180,
                "x1": 418.1911999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "the chair, has put that",
                "page": 39,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "investigation to bed. Now we’re",
                "page": 39,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "getting some uncomfortable",
                "page": 39,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "attention from a Wolfgang McGee,",
                "page": 39,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "with whom I believe you’re",
                "page": 39,
                "x0": 180,
                "x1": 362.1899999999999,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "familiar.",
                "page": 39,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "AL",
                "page": 39,
                "x0": 252,
                "x1": 266.1996,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "I am.",
                "page": 39,
                "x0": 180,
                "x1": 215.1984,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "CORMACK",
                "page": 39,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "He’s trying to shine a light upon",
                "page": 39,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "this affair at a critical juncture",
                "page": 39,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "for us.",
                "page": 39,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "AL",
                "page": 39,
                "x0": 252,
                "x1": 266.1996,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "You said I could help.",
                "page": 39,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "CORMACK",
                "page": 39,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "Steer him off it. Find out what",
                "page": 39,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "he’ll take to go away.",
                "page": 39,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "AL",
                "page": 39,
                "x0": 252,
                "x1": 266.1996,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "Not sure he’s that sort.",
                "page": 39,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "CORMACK",
                "page": 39,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Everyone’s that sort eventually.",
                "page": 39,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "A beat as this lands on Al and his future prospects.",
                "page": 39,
                "x0": 108,
                "x1": 472.1796000000002,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "CORMACK (CONT’D)",
                "page": 39,
                "x0": 252,
                "x1": 364.1968,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "It’s something we’d be very",
                "page": 39,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "grateful to see go away.",
                "page": 39,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "AL",
                "page": 39,
                "x0": 252,
                "x1": 266.1996,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "I’ll have a chat with Wolfgang.",
                "page": 39,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "CORMACK",
                "page": 39,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "Good. And see if you can’t find out",
                "page": 39,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "who’s pulling his strings while",
                "page": 39,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "you’re at it. We have our own ideas",
                "page": 39,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "of course but it would be nice to",
                "page": 39,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "confirm.",
                "page": 39,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "AL",
                "page": 39,
                "x0": 252,
                "x1": 266.1996,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "I’ll do my best.",
                "page": 39,
                "x0": 180,
                "x1": 292.1939999999999,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 39,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 37.",
                "page": 40,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "42a CONTINUED: (2) 42a",
                "page": 40,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "CORMACK",
                "page": 40,
                "x0": 252,
                "x1": 301.19759999999997,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Now, can we talk a bit about your",
                "page": 40,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "transition. You’re married, will",
                "page": 40,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "your wife be accompanying you to",
                "page": 40,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "Ottawa.",
                "page": 40,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "AL",
                "page": 40,
                "x0": 252,
                "x1": 266.1996,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "Anne, no she’ll be staying.",
                "page": 40,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            }
        ],
        "scene_number": "42a",
        "int_ext": "INT.",
        "location": "CHARLIE’S HOTEL SUITE",
        "time": "DAY"
    },
    {
        "text": "42b INT. HOTEL CORRIDOR - DAY 42b",
        "page": 40,
        "lines": [
            {
                "text": "As Al retreats, clearly anxious.",
                "page": 40,
                "x0": 108,
                "x1": 332.1875999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            }
        ],
        "scene_number": "42b",
        "int_ext": "INT.",
        "location": "HOTEL CORRIDOR",
        "time": "DAY"
    },
    {
        "text": "42c INT. WOLFGANG’S OFFICE - DAY 42c",
        "page": 40,
        "lines": [
            {
                "text": "Wolfgang admits Al into his office.",
                "page": 40,
                "x0": 108,
                "x1": 353.18639999999994,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "WOLFGANG",
                "page": 40,
                "x0": 252,
                "x1": 308.1972,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "This a rare treat. To what do I",
                "page": 40,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "owe the pleasure.",
                "page": 40,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "He closes the door behind Al.",
                "page": 40,
                "x0": 108,
                "x1": 311.18879999999984,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "AL",
                "page": 40,
                "x0": 252,
                "x1": 266.1996,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "I’ve come to ask you what trouble",
                "page": 40,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "you’ve managed to land yourself in",
                "page": 40,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "the middle of.",
                "page": 40,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "WOLFGANG",
                "page": 40,
                "x0": 252,
                "x1": 308.1972,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Is this about Sproule?",
                "page": 40,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "AL",
                "page": 40,
                "x0": 252,
                "x1": 266.1996,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "In a roundabout sort of way. I’ve",
                "page": 40,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "been tipped for the chair left",
                "page": 40,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "empty by his demise.",
                "page": 40,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "WOLFGANG",
                "page": 40,
                "x0": 252,
                "x1": 308.1972,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "You’re taking the job.",
                "page": 40,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "AL",
                "page": 40,
                "x0": 252,
                "x1": 266.1996,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "I haven’t decided. But tell me,",
                "page": 40,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "what is it that you’ve done to piss",
                "page": 40,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "off the big boys.",
                "page": 40,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "WOLFGANG",
                "page": 40,
                "x0": 252,
                "x1": 308.1972,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "That’s why you’re here I suppose,",
                "page": 40,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "to tell me.",
                "page": 40,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "AL",
                "page": 40,
                "x0": 252,
                "x1": 266.1996,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "It’s this bomb at the ballpark",
                "page": 40,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "affair.",
                "page": 40,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 40,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Goldenrod (09/12/2016) 38.",
                "page": 41,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "42c CONTINUED: 42c",
                "page": 41,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "WOLFGANG",
                "page": 41,
                "x0": 252,
                "x1": 308.1972,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "It’s a bit smelly Al.",
                "page": 41,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "AL",
                "page": 41,
                "x0": 252,
                "x1": 266.1996,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "Yes, I’ll take your word for it.",
                "page": 41,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "What exactly are the potential",
                "page": 41,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "ramifications of whatever it is",
                "page": 41,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "you’re investigating.",
                "page": 41,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 41,
                "x0": 252,
                "x1": 308.1972,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "Longshot, a half a dozen",
                "page": 41,
                "x0": 180,
                "x1": 348.1939999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "convictions of some very important",
                "page": 41,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "people for accessory to murder.",
                "page": 41,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "But I don’t believe there’s the",
                "page": 41,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "appetite for prosecuting the",
                "page": 41,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "intelligence service. Be a first",
                "page": 41,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "if there was.",
                "page": 41,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "AL",
                "page": 41,
                "x0": 252,
                "x1": 266.1996,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "I don’t suppose you’ll be backing",
                "page": 41,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "off.",
                "page": 41,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "Wolfgang gives him an enigmatic shrug.",
                "page": 41,
                "x0": 108,
                "x1": 374.1852,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "WOLFGANG",
                "page": 41,
                "x0": 252,
                "x1": 308.1972,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "Why won’t you take the top job.",
                "page": 41,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "You’ve been eyeing it a long time.",
                "page": 41,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "AL",
                "page": 41,
                "x0": 252,
                "x1": 266.1996,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "Yes, well. I guess I question what",
                "page": 41,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "good could I accomplish.",
                "page": 41,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "WOLFGANG",
                "page": 41,
                "x0": 252,
                "x1": 308.1972,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "Maybe you could prevent a few bad",
                "page": 41,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "things from happening.",
                "page": 41,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "AL",
                "page": 41,
                "x0": 252,
                "x1": 266.1996,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "So - you won’t back off digging",
                "page": 41,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "into this Quiller Inquiry thing.",
                "page": 41,
                "x0": 180,
                "x1": 404.1943999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "WOLFGANG",
                "page": 41,
                "x0": 252,
                "x1": 308.1972,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "I’ve taken on the obligation. Got",
                "page": 41,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "to see it through to the end. So",
                "page": 41,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "no.",
                "page": 41,
                "x0": 180,
                "x1": 201.1992,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "(CONTINUED)",
                "page": 41,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 39.",
                "page": 42,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "42c CONTINUED: (2) 42c",
                "page": 42,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "AL",
                "page": 42,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Now I’m curious who put you onto",
                "page": 42,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "this.",
                "page": 42,
                "x0": 180,
                "x1": 215.1984,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 42,
                "x0": 252,
                "x1": 308.1972,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "I put myself onto it.",
                "page": 42,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "AL",
                "page": 42,
                "x0": 252,
                "x1": 266.1996,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "I see. Don’t suppose you’d ever",
                "page": 42,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "consider coming back into the ranks",
                "page": 42,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "if I were to offer you something",
                "page": 42,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "sweet?",
                "page": 42,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "WOLFGANG",
                "page": 42,
                "x0": 252,
                "x1": 308.1972,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "No, no, I wouldn’t.",
                "page": 42,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "AL",
                "page": 42,
                "x0": 252,
                "x1": 266.1996,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "On to other matters then. Very",
                "page": 42,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "unfortunate about old Sproule.",
                "page": 42,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "WOLFGANG",
                "page": 42,
                "x0": 252,
                "x1": 308.1972,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "Peculiar as well.",
                "page": 42,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "AL",
                "page": 42,
                "x0": 252,
                "x1": 266.1996,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "I think so.",
                "page": 42,
                "x0": 180,
                "x1": 257.19599999999997,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "WOLFGANG",
                "page": 42,
                "x0": 252,
                "x1": 308.1972,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "Would our mutual friend be involved",
                "page": 42,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "somehow?",
                "page": 42,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "AL",
                "page": 42,
                "x0": 252,
                "x1": 266.1996,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "I believe she is.",
                "page": 42,
                "x0": 180,
                "x1": 299.19359999999995,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "WOLFGANG",
                "page": 42,
                "x0": 252,
                "x1": 308.1972,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "How involved.",
                "page": 42,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "AL",
                "page": 42,
                "x0": 252,
                "x1": 266.1996,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "Fatally. And I’m open to ideas how",
                "page": 42,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "to handle the situation.",
                "page": 42,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "Considering the potential",
                "page": 42,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "consequences of the story going",
                "page": 42,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "public - for not only myself, but",
                "page": 42,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "yourself, Mr. Sproule, and quite",
                "page": 42,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "possibly the integrity of the",
                "page": 42,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "entire agency.",
                "page": 42,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "Wolfgang takes a beat.",
                "page": 42,
                "x0": 108,
                "x1": 262.19159999999994,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "WOLFGANG",
                "page": 42,
                "x0": 252,
                "x1": 308.1972,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "You’ve got to get rid of her",
                "page": 42,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "quickly and cleanly. As we should",
                "page": 42,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "have done ages ago. She’s made",
                "page": 42,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "fools of us all.",
                "page": 42,
                "x0": 180,
                "x1": 292.1939999999999,
                "y0": 696.592,
                "y1": 708.592
            },
            {
                "text": "(CONTINUED)",
                "page": 42,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Green (09/09/2016) 40.",
                "page": 43,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "42c CONTINUED: (3) 42c",
                "page": 43,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "AL",
                "page": 43,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Again, I’m open to suggestions.",
                "page": 43,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            }
        ],
        "scene_number": "42c",
        "int_ext": "INT.",
        "location": "WOLFGANG’S OFFICE",
        "time": "DAY"
    },
    {
        "text": "42d INT. PRC - CORRIDOR - DAY 42d",
        "page": 43,
        "lines": [
            {
                "text": "DETECTIVE GREEN waits for Al outside his office. Al",
                "page": 43,
                "x0": 108,
                "x1": 465.1800000000002,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "approaches.",
                "page": 43,
                "x0": 108,
                "x1": 185.19599999999997,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "AL",
                "page": 43,
                "x0": 252,
                "x1": 266.1996,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "Detective.",
                "page": 43,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 43,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "There you are.",
                "page": 43,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "AL",
                "page": 43,
                "x0": 252,
                "x1": 266.1996,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "How can I help you.",
                "page": 43,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "Al opens the door for her.",
                "page": 43,
                "x0": 108,
                "x1": 290.2,
                "y0": 288.592,
                "y1": 300.592
            }
        ],
        "scene_number": "42d",
        "int_ext": "INT.",
        "location": "PRC - CORRIDOR",
        "time": "DAY"
    },
    {
        "text": "43 INT. AL’S OFFICE - DAY 43",
        "page": 43,
        "lines": [
            {
                "text": "Al closes the door.",
                "page": 43,
                "x0": 108,
                "x1": 241.19279999999992,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 43,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "I might have spoken too soon",
                "page": 43,
                "x0": 180,
                "x1": 376.1968,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "earlier.",
                "page": 43,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "AL",
                "page": 43,
                "x0": 252,
                "x1": 266.1996,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "Oh?",
                "page": 43,
                "x0": 180,
                "x1": 201.1992,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "Al sets down his backpack full of TOOLS and CAMERAS.",
                "page": 43,
                "x0": 108,
                "x1": 472.1796000000002,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 43,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "I’m starting to wonder if there",
                "page": 43,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "wasn’t somebody else in that room",
                "page": 43,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "with Mister Sproule.",
                "page": 43,
                "x0": 180,
                "x1": 320.1923999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "Al puzzles.",
                "page": 43,
                "x0": 108,
                "x1": 185.19599999999997,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "AL",
                "page": 43,
                "x0": 252,
                "x1": 266.1996,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "Like who.",
                "page": 43,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 43,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "Don’t know. Something funny about",
                "page": 43,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "it though.",
                "page": 43,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "AL",
                "page": 43,
                "x0": 252,
                "x1": 266.1996,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "How so.",
                "page": 43,
                "x0": 180,
                "x1": 229.19759999999997,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "(CONTINUED)",
                "page": 43,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 41.",
                "page": 44,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "43 CONTINUED: 43",
                "page": 44,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 44,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "The wine. The liver. The box of",
                "page": 44,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "condoms under the bed.",
                "page": 44,
                "x0": 180,
                "x1": 334.1943999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "AL",
                "page": 44,
                "x0": 252,
                "x1": 266.1996,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "Hmm.",
                "page": 44,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 44,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "Correct me if I’m wrong but that’s",
                "page": 44,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "a safe house, is it not.",
                "page": 44,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "AL",
                "page": 44,
                "x0": 252,
                "x1": 266.1996,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "It is.",
                "page": 44,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 44,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "Then what’s an Intel guy doing in a",
                "page": 44,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "safe house if not meeting with",
                "page": 44,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "somebody.",
                "page": 44,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "AL",
                "page": 44,
                "x0": 252,
                "x1": 266.1996,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "That’s a good question.",
                "page": 44,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 44,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "I understand him and his wife were",
                "page": 44,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "in the process of separating.",
                "page": 44,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "AL",
                "page": 44,
                "x0": 252,
                "x1": 266.1996,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "I believe that’s true, yes.",
                "page": 44,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 44,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "You know her well? His wife.",
                "page": 44,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "AL",
                "page": 44,
                "x0": 252,
                "x1": 266.1996,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "Yes. A little. We used to be much",
                "page": 44,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "closer, all of us.",
                "page": 44,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 44,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "He talk about her lately? He say",
                "page": 44,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "anything about what they might have",
                "page": 44,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "been going through?",
                "page": 44,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "Al takes a moment.",
                "page": 44,
                "x0": 108,
                "x1": 234.19319999999993,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "AL",
                "page": 44,
                "x0": 252,
                "x1": 266.1996,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "Eddie did tell me she was going",
                "page": 44,
                "x0": 180,
                "x1": 397.19359999999995,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "through a black depression. He was",
                "page": 44,
                "x0": 180,
                "x1": 425.198,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "having a devil of a time leaving",
                "page": 44,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "her because he said he was worried",
                "page": 44,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "about what she might do.",
                "page": 44,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "Detective Green nods.",
                "page": 44,
                "x0": 108,
                "x1": 255.1919999999999,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 44,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 42.",
                "page": 45,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "43 CONTINUED: (2) 43",
                "page": 45,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "DETECTIVE GREEN",
                "page": 45,
                "x0": 252,
                "x1": 357.1943999999999,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Okay. This is helpful.",
                "page": 45,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "Al takes a breath to digest.",
                "page": 45,
                "x0": 108,
                "x1": 304.18919999999986,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "AL",
                "page": 45,
                "x0": 252,
                "x1": 266.1996,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "I hadn’t even considered her in",
                "page": 45,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "this. My God.",
                "page": 45,
                "x0": 180,
                "x1": 278.19919999999996,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            }
        ],
        "scene_number": "43",
        "int_ext": "INT.",
        "location": "AL’S OFFICE",
        "time": "DAY"
    },
    {
        "text": "44 INT. MOVIE SET - DAY (FOR NIGHT) 44",
        "page": 45,
        "lines": [
            {
                "text": "Tony and Mei Mei whisper in between takes.",
                "page": 45,
                "x0": 108,
                "x1": 402.1899999999999,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "TONY",
                "page": 45,
                "x0": 252,
                "x1": 280.1988,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "It never occurred to you to let me",
                "page": 45,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "know you’re under fuckin’ contract",
                "page": 45,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "to Jimmy Wah.",
                "page": 45,
                "x0": 180,
                "x1": 271.2,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "MEI MEI",
                "page": 45,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "Don’t listen to him. He’s only",
                "page": 45,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 312.592,
                "y1": 324.592
            },
            {
                "text": "trying to intimidate you.",
                "page": 45,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "TONY",
                "page": 45,
                "x0": 252,
                "x1": 280.1988,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "And it’s fucking working. You know",
                "page": 45,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "he can shut this whole thing down.",
                "page": 45,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "MEI MEI",
                "page": 45,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "I never signed a formal contract",
                "page": 45,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "with him, he has nothing.",
                "page": 45,
                "x0": 180,
                "x1": 355.1903999999999,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "TONY",
                "page": 45,
                "x0": 252,
                "x1": 280.1988,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "Like that matters with him. You’ve",
                "page": 45,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "got to talk with him and figure it",
                "page": 45,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "out.",
                "page": 45,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "MEI MEI",
                "page": 45,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "He makes my skin crawl -",
                "page": 45,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "TONY",
                "page": 45,
                "x0": 252,
                "x1": 280.1988,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "One dinner. Charm him.",
                "page": 45,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "MEI MEI",
                "page": 45,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "I’d rather chew glass.",
                "page": 45,
                "x0": 180,
                "x1": 334.19159999999994,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "TONY",
                "page": 45,
                "x0": 252,
                "x1": 280.1988,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "Tell him how important this movie",
                "page": 45,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "is to you and thank him for",
                "page": 45,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "everything he’s done for your",
                "page": 45,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "career - he’s got an ego. Stroke",
                "page": 45,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "it.",
                "page": 45,
                "x0": 180,
                "x1": 201.1992,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 45,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 43.",
                "page": 46,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "44 CONTINUED: 44",
                "page": 46,
                "x0": 54,
                "x1": 526.1996,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "MEI MEI",
                "page": 46,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "He looked after me for a couple",
                "page": 46,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "months when I was 16 and now he",
                "page": 46,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "thinks he owns me for life. I",
                "page": 46,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "don’t want him anywhere near this",
                "page": 46,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "set. You do what you have to do",
                "page": 46,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "and get rid of him.",
                "page": 46,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "TONY",
                "page": 46,
                "x0": 252,
                "x1": 280.1988,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "Mei Mei. I’m begging you. We still",
                "page": 46,
                "x0": 180,
                "x1": 425.18919999999986,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "have half a movie to shoot. We",
                "page": 46,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "can’t afford to piss the guy off",
                "page": 46,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "right now.",
                "page": 46,
                "x0": 180,
                "x1": 250.19639999999998,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "MEI MEI",
                "page": 46,
                "x0": 252,
                "x1": 301.19919999999996,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "That’s why - as my producer - I",
                "page": 46,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "expect you to do your job. Grow",
                "page": 46,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "some balls, Tony. How about that.",
                "page": 46,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "Tony walks away before he says something he really regrets.",
                "page": 46,
                "x0": 108,
                "x1": 521.1768000000003,
                "y0": 300.592,
                "y1": 312.592
            }
        ],
        "scene_number": "44",
        "int_ext": "INT.",
        "location": "MOVIE SET",
        "time": "DAY"
    },
    {
        "text": "46a EXT. THE HEIGHTS ABOVE LIONS GATE BRIDGE - SUNSET 46a",
        "page": 46,
        "lines": [
            {
                "text": "LILY parks her car.",
                "page": 46,
                "x0": 108,
                "x1": 241.19279999999992,
                "y0": 432.592,
                "y1": 444.592
            }
        ],
        "scene_number": "46a",
        "int_ext": "EXT.",
        "location": "THE HEIGHTS ABOVE LIONS GATE BRIDGE",
        "time": "SUNSET"
    },
    {
        "text": "46b EXT. PROSPECT POINT - FURTHER - SUNSET 46b",
        "page": 46,
        "lines": [
            {
                "text": "Lily finds Al overlooking the harbour and ships below.",
                "page": 46,
                "x0": 108,
                "x1": 486.17880000000025,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "LILY",
                "page": 46,
                "x0": 252,
                "x1": 280.1988,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "What is it. You look like you’ve",
                "page": 46,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "seen a ghost.",
                "page": 46,
                "x0": 180,
                "x1": 271.19519999999994,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "AL",
                "page": 46,
                "x0": 252,
                "x1": 266.1996,
                "y0": 564.592,
                "y1": 576.592
            },
            {
                "text": "Edmund Sproule is dead.",
                "page": 46,
                "x0": 180,
                "x1": 341.1911999999999,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "She gasps.",
                "page": 46,
                "x0": 108,
                "x1": 178.19639999999998,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "LILY",
                "page": 46,
                "x0": 252,
                "x1": 280.1988,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "When?",
                "page": 46,
                "x0": 180,
                "x1": 215.1984,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "AL",
                "page": 46,
                "x0": 252,
                "x1": 266.1996,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "Early this morning, late last",
                "page": 46,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "night.",
                "page": 46,
                "x0": 180,
                "x1": 222.19799999999998,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 46,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 44.",
                "page": 47,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "46b CONTINUED: 46b",
                "page": 47,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "LILY",
                "page": 47,
                "x0": 252,
                "x1": 280.1988,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "How.",
                "page": 47,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "AL",
                "page": 47,
                "x0": 252,
                "x1": 266.1996,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "Heart attack they think.",
                "page": 47,
                "x0": 180,
                "x1": 348.1907999999999,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "Lily tears up a little. Al just watches.",
                "page": 47,
                "x0": 108,
                "x1": 395.184,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "LILY",
                "page": 47,
                "x0": 252,
                "x1": 280.1988,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "I’m sorry. I know he was your",
                "page": 47,
                "x0": 180,
                "x1": 390.1883999999999,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "friend at some point.",
                "page": 47,
                "x0": 180,
                "x1": 327.1919999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "AL",
                "page": 47,
                "x0": 252,
                "x1": 266.1996,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "Yes, he had been a friend. A good",
                "page": 47,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 228.59199999999998,
                "y1": 240.59199999999998
            },
            {
                "text": "one.",
                "page": 47,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "She hugs him. Holds to his chest.",
                "page": 47,
                "x0": 108,
                "x1": 346.18679999999995,
                "y0": 264.592,
                "y1": 276.592
            },
            {
                "text": "LILY",
                "page": 47,
                "x0": 252,
                "x1": 280.1988,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "Now what.",
                "page": 47,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "AL",
                "page": 47,
                "x0": 252,
                "x1": 266.1996,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "Very good question.",
                "page": 47,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 336.592,
                "y1": 348.592
            },
            {
                "text": "She looks up to him.",
                "page": 47,
                "x0": 108,
                "x1": 248.1923999999999,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "AL (CONT’D)",
                "page": 47,
                "x0": 252,
                "x1": 329.1968,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "I’m feeling, my dear, that it might",
                "page": 47,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "be time for you to get out of the",
                "page": 47,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "game.",
                "page": 47,
                "x0": 180,
                "x1": 215.1984,
                "y0": 420.592,
                "y1": 432.592
            },
            {
                "text": "She pulls away.",
                "page": 47,
                "x0": 108,
                "x1": 213.19439999999994,
                "y0": 444.592,
                "y1": 456.592
            },
            {
                "text": "AL (CONT’D)",
                "page": 47,
                "x0": 252,
                "x1": 329.1968,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "I once visited a little place just",
                "page": 47,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "on the coast below Manzanillo. It",
                "page": 47,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "had a beautiful little bay, just",
                "page": 47,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "enough to anchor your boat, and",
                "page": 47,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "fish and fresh water, all you",
                "page": 47,
                "x0": 180,
                "x1": 383.18879999999984,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "really needed. If you didn’t want",
                "page": 47,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "to see anybody, it was easy.",
                "page": 47,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "LILY",
                "page": 47,
                "x0": 252,
                "x1": 280.1988,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "That sounds like a beautiful spot.",
                "page": 47,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "AL",
                "page": 47,
                "x0": 252,
                "x1": 266.1996,
                "y0": 612.592,
                "y1": 624.592
            },
            {
                "text": "I see it in my mind every single",
                "page": 47,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "day. Would you like to visit there",
                "page": 47,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "sometime?",
                "page": 47,
                "x0": 180,
                "x1": 243.19679999999997,
                "y0": 648.592,
                "y1": 660.592
            },
            {
                "text": "LILY",
                "page": 47,
                "x0": 252,
                "x1": 280.1988,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "When would that be.",
                "page": 47,
                "x0": 180,
                "x1": 313.1927999999999,
                "y0": 684.592,
                "y1": 696.592
            },
            {
                "text": "(CONTINUED)",
                "page": 47,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 45.",
                "page": 48,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "46b CONTINUED: (2) 46b",
                "page": 48,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "AL",
                "page": 48,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Fairly urgently I’d say. I know",
                "page": 48,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "what you did. I saw what you did.",
                "page": 48,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 96.59199999999998,
                "y1": 108.59199999999998
            },
            {
                "text": "LILY",
                "page": 48,
                "x0": 252,
                "x1": 280.1988,
                "y0": 120.59199999999998,
                "y1": 132.59199999999998
            },
            {
                "text": "What exactly are you accusing me",
                "page": 48,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "of.",
                "page": 48,
                "x0": 180,
                "x1": 201.1992,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "AL",
                "page": 48,
                "x0": 252,
                "x1": 266.1996,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "Murder. You poisoned Edmund. That",
                "page": 48,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 180.59199999999998,
                "y1": 192.59199999999998
            },
            {
                "text": "will all be eventually discerned,",
                "page": 48,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "but by that time, you can be far",
                "page": 48,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 204.59199999999998,
                "y1": 216.59199999999998
            },
            {
                "text": "away, on that beautiful bay.",
                "page": 48,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            },
            {
                "text": "LILY",
                "page": 48,
                "x0": 252,
                "x1": 280.1988,
                "y0": 240.59199999999998,
                "y1": 252.59199999999998
            },
            {
                "text": "How.",
                "page": 48,
                "x0": 180,
                "x1": 208.19879999999998,
                "y0": 252.59199999999998,
                "y1": 264.592
            },
            {
                "text": "AL",
                "page": 48,
                "x0": 252,
                "x1": 266.1996,
                "y0": 276.592,
                "y1": 288.592
            },
            {
                "text": "Your sudden disappearance will be",
                "page": 48,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 288.592,
                "y1": 300.592
            },
            {
                "text": "the best for all concerned.",
                "page": 48,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 300.592,
                "y1": 312.592
            },
            {
                "text": "Al points out the parking lot distant.",
                "page": 48,
                "x0": 108,
                "x1": 374.1852,
                "y0": 324.592,
                "y1": 336.592
            },
            {
                "text": "AL (CONT’D)",
                "page": 48,
                "x0": 252,
                "x1": 329.1968,
                "y0": 348.592,
                "y1": 360.592
            },
            {
                "text": "Your car would be found just there.",
                "page": 48,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 360.592,
                "y1": 372.592
            },
            {
                "text": "There might be a note, a short one",
                "page": 48,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 372.592,
                "y1": 384.592
            },
            {
                "text": "if there is. Something succinct.",
                "page": 48,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 384.592,
                "y1": 396.592
            },
            {
                "text": "“I can no longer live with what",
                "page": 48,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 396.592,
                "y1": 408.592
            },
            {
                "text": "I’ve done”, something like that.",
                "page": 48,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 408.592,
                "y1": 420.592
            },
            {
                "text": "He walks to a bench.",
                "page": 48,
                "x0": 108,
                "x1": 248.1923999999999,
                "y0": 432.592,
                "y1": 444.592
            },
            {
                "text": "AL (CONT’D)",
                "page": 48,
                "x0": 252,
                "x1": 329.1968,
                "y0": 456.592,
                "y1": 468.592
            },
            {
                "text": "Here they’ll find your purse. Over",
                "page": 48,
                "x0": 180,
                "x1": 425.18639999999994,
                "y0": 468.592,
                "y1": 480.592
            },
            {
                "text": "by the fence, your shoes. Maybe",
                "page": 48,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 480.592,
                "y1": 492.592
            },
            {
                "text": "tear a bit of fabric going over,",
                "page": 48,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 492.592,
                "y1": 504.592
            },
            {
                "text": "leave some evidence. Lover’s leap",
                "page": 48,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 504.592,
                "y1": 516.592
            },
            {
                "text": "they used to call this. But",
                "page": 48,
                "x0": 180,
                "x1": 376.18919999999986,
                "y0": 516.592,
                "y1": 528.592
            },
            {
                "text": "tragedy seems out of fashion so",
                "page": 48,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 528.592,
                "y1": 540.592
            },
            {
                "text": "they don’t call it anything",
                "page": 48,
                "x0": 180,
                "x1": 369.18959999999987,
                "y0": 540.592,
                "y1": 552.592
            },
            {
                "text": "anymore.",
                "page": 48,
                "x0": 180,
                "x1": 236.19719999999998,
                "y0": 552.592,
                "y1": 564.592
            },
            {
                "text": "LILY",
                "page": 48,
                "x0": 252,
                "x1": 280.1988,
                "y0": 576.592,
                "y1": 588.592
            },
            {
                "text": "I can’t just disappear into thin",
                "page": 48,
                "x0": 180,
                "x1": 404.1875999999999,
                "y0": 588.592,
                "y1": 600.592
            },
            {
                "text": "air -",
                "page": 48,
                "x0": 180,
                "x1": 215.1984,
                "y0": 600.592,
                "y1": 612.592
            },
            {
                "text": "AL",
                "page": 48,
                "x0": 252,
                "x1": 266.1996,
                "y0": 624.592,
                "y1": 636.592
            },
            {
                "text": "It’s the simplest way. Vanish.",
                "page": 48,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 636.592,
                "y1": 648.592
            },
            {
                "text": "LILY",
                "page": 48,
                "x0": 252,
                "x1": 280.1988,
                "y0": 660.592,
                "y1": 672.592
            },
            {
                "text": "You’re scaring me.",
                "page": 48,
                "x0": 180,
                "x1": 306.19319999999993,
                "y0": 672.592,
                "y1": 684.592
            },
            {
                "text": "(CONTINUED)",
                "page": 48,
                "x0": 463,
                "x1": 540.196,
                "y0": 743.592,
                "y1": 755.592
            },
            {
                "text": "Ep. 208 Pink (09/07/2016) 46.",
                "page": 49,
                "x0": 90,
                "x1": 522.1992,
                "y0": 35.591999999999985,
                "y1": 47.591999999999985
            },
            {
                "text": "46b CONTINUED: (3) 46b",
                "page": 49,
                "x0": 54,
                "x1": 526.1992,
                "y0": 48.591999999999985,
                "y1": 60.591999999999985
            },
            {
                "text": "AL",
                "page": 49,
                "x0": 252,
                "x1": 266.1996,
                "y0": 72.59199999999998,
                "y1": 84.59199999999998
            },
            {
                "text": "Sometimes that’s what it takes.",
                "page": 49,
                "x0": 180,
                "x1": 397.1879999999999,
                "y0": 84.59199999999998,
                "y1": 96.59199999999998
            },
            {
                "text": "He takes her hand gently.",
                "page": 49,
                "x0": 108,
                "x1": 283.1903999999999,
                "y0": 108.59199999999998,
                "y1": 120.59199999999998
            },
            {
                "text": "AL (CONT’D)",
                "page": 49,
                "x0": 252,
                "x1": 329.1968,
                "y0": 132.59199999999998,
                "y1": 144.59199999999998
            },
            {
                "text": "Anyway my dear, it’s something to",
                "page": 49,
                "x0": 180,
                "x1": 411.1871999999999,
                "y0": 144.59199999999998,
                "y1": 156.59199999999998
            },
            {
                "text": "think about. But it’s best not to",
                "page": 49,
                "x0": 180,
                "x1": 418.18679999999995,
                "y0": 156.59199999999998,
                "y1": 168.59199999999998
            },
            {
                "text": "wait too long.",
                "page": 49,
                "x0": 180,
                "x1": 278.19479999999993,
                "y0": 168.59199999999998,
                "y1": 180.59199999999998
            },
            {
                "text": "He looks off into the sunset.",
                "page": 49,
                "x0": 108,
                "x1": 311.18879999999984,
                "y0": 192.59199999999998,
                "y1": 204.59199999999998
            },
            {
                "text": "FADE OUT:",
                "page": 49,
                "x0": 449,
                "x1": 512.1968,
                "y0": 216.59199999999998,
                "y1": 228.59199999999998
            }
        ],
        "scene_number": "46b",
        "int_ext": "EXT.",
        "location": "PROSPECT POINT - FURTHER",
        "time": "SUNSET"
    }
]

export {data};