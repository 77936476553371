import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import Modal from "../Modal/Modal";


const SceneTable = (props) => {

    const [locations, setLocations] = useState(null);
    const [times, setTimes] = useState(null);
    const [locationFilter, setLocationFilter] = useState([]);
    const [filteredScenes, setFilteredScenes] = useState([]);
    const [timeFilter, setTimeFilter] = useState([]);
    const [modalScenes, setModalScenes] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    
    useEffect(() => {
        const uniqueLocations = props.sceneList.map((scene) => (scene.location))
                                    .filter((val,id,array) => array.indexOf(val) === id)
                                    .sort();
        setLocations(uniqueLocations.map((loc) => 
            ({ value: loc, label: loc })
        ));

        const uniqueTimes = props.sceneList.map((scene) => (scene.time))
                                    .filter((val,id,array) => array.indexOf(val) === id)
                                    .sort();
        setTimes(uniqueTimes.map((time) => 
            ({ value: time, label: time })
        ));
    }, [props.sceneList]);

    const handleSceneClick = (scene) => {
        setModalScenes([scene]);
        setModalShow(true);
    }

    const handleSeeAll = () => {
        setModalScenes(filteredScenes);
        setModalShow(true);
    };

    useEffect(() => {
        const locFilteredScenes = locationFilter.length > 0 ? 
            props.sceneList.filter((scene) => (locationFilter.indexOf(scene.location) > -1))
            :
            props.sceneList;

        const timeFilteredScenes = timeFilter.length > 0 ? 
            locFilteredScenes.filter((scene) => (timeFilter.indexOf(scene.time) > -1))
            :
            locFilteredScenes;
        setFilteredScenes(timeFilteredScenes)
    }, [locationFilter, timeFilter, props.sceneList])

    const generateTable = () => {
        if (filteredScenes.length === 0) {
            return <p>No results.</p>
        }

        return <div className="scene-container">
            <div className="scene-header-row">
                <div className="scene-cell-text">Header</div>
                <div className="scene-cell-page">Page</div>
                <div className="scene-cell-number">No.</div>
                <div className="scene-cell-int">I/E</div>
                <div className="scene-cell-location">Location</div>
                <div className="scene-cell-time">Time</div>
                <div className="scene-cell-expand"></div>
            </div>
            {filteredScenes.map((scene, index) => (
                <div key={index} className="scene-row">
                    <div className="scene-cell-text">{scene.text}</div>
                    <div className="scene-cell-page">{scene.page}</div>
                    <div className="scene-cell-number">{scene.scene_number}</div>
                    <div className="scene-cell-int">{scene.int_ext}</div>
                    <div className="scene-cell-location">{scene.location}</div>
                    <div className="scene-cell-time">{scene.time}</div>
                    <div className="scene-cell-expand" onClick={() => handleSceneClick(scene)}>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </div>
                </div>
            ))}
            <Modal scenes={modalScenes} show={modalShow} onClose={() => setModalShow(false)}/>
        </div>
    }

    const onLocationChange = (newValue) => {
        setLocationFilter(newValue.map((v) => (v.value)));
    };

    const onTimeChange = (newValue) => {
        setTimeFilter(newValue.map((v) => (v.value)));
    };

    const darkColors = {
        primary: '#eee',
        primary75: '#333',
        primary50: '#666',
        primary25: '#999',
        danger: '#999',
        dangerLight: '#eee',
        neutral0: '#444',
        neutral5: '#555',
        neutral10: '#888',
        neutral20: '#777',
        neutral30: '#888',
        neutral40: '#999',
        neutral50: '#aaa',
        neutral60: '#bbb',
        neutral70: '#ccc',
        neutral80: '#ddd',
        neutral90: '#eee',
    }

    return (
        <div>
            <h3>Scenes:</h3>
            <div className='filters'>
                <div className='loc-filter'>
                    {locations && <Select 
                        options={locations} 
                        closeMenuOnSelect={false}
                        isMulti
                        placeholder='Filter location ...'
                        onChange={onLocationChange}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: darkColors,
                        })}
                    />}
                </div>
                <div className='time-filter'>
                    {times && <Select 
                        options={times} 
                        closeMenuOnSelect={false}
                        isMulti
                        placeholder='Filter time ...'
                        onChange={onTimeChange}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: darkColors,
                        })}
                    />}
                </div>
                { (locationFilter.length > 0 || timeFilter.length > 0) && filteredScenes.length > 0 && 
                    <div className="btn-see-all" onClick={handleSeeAll}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></div>}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {generateTable()}
            </div>
        </div>
    )
    
}

export default SceneTable