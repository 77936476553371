import React from "react"
import './Modal.css'

const Modal = (props) => {
    if (!props.show) {
        return null;
    }

    const generateScriptLines = (scenes) => {
        const outputs = []
        let i=0;
        for (const scene of scenes) {
            outputs.push(
                <p key={'s'+i} className="modal-header">{scene.text}</p>
            );
            let j=0;
            for (const line of scene.lines) {
                outputs.push(
                    <p key={'s'+i+'l'+j} className="modal-line">{line.text}</p>
                );
                j+=1;
            }
            i+=1;
        }
        return outputs;
    }

    const generateLocations = (scenes) => {
        const locs = scenes.map((scene) => (scene.location))
                            .filter((val,id,array) => array.indexOf(val) === id)
                            .sort();
        return <div>
                <p key='loc0' className="prop-header">Location</p>
                <p key='loc1' className="prop-value">{locs.join(", ")}</p>
            </div>

    }

    const generateTimes = (scenes) => {
        const tims = scenes.map((scene) => (scene.time))
                            .filter((val,id,array) => array.indexOf(val) === id)
                            .sort();
        return <div>
                <p key='tim0' className="prop-header">Time</p>
                <p key='tim1' className="prop-value">{tims.join(", ")}</p>
            </div>
    }

    const generateStub = (text) => {
        return <div>
                <p className="prop-header">{text}</p>
                <p className="prop-stub">Coming soon ...</p>
            </div>
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-lines">
                        {generateScriptLines(props.scenes)}
                    </div>
                    <div className="modal-properties">
                        <div className="modal-property">
                            {generateLocations(props.scenes)}
                        </div>
                        <div className="modal-property">
                            {generateTimes(props.scenes)}
                        </div>
                        <div className="modal-property">
                            {generateStub("Tags")}
                        </div>
                        <div className="modal-property">
                            {generateStub("Special Equipment")}
                        </div>
                        <div className="modal-property">
                            {generateStub("Gallery")}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={props.onClose}>Close</button>
                    <button disabled={true}>Export to PDF</button>
                    <button disabled={true}>Go To Schedule</button>
                </div>
            </div>
        </div>
    )
    
}

export default Modal