import React, { useState } from 'react';
import SceneTable from '../../components/SceneTable/SceneTable';
import { data } from './scenes'

const Home = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const [uploading, setUploading] = useState(false);
    const [fileId, setFileId] = useState(null);
    const [uploadError, setUploadError] = useState(null);

    const [processingES, setProcessingES] = useState(false);
    const [sceneList, setSceneList] = useState(null);
    const [ESError, setESError] = useState(null);

    const [processingESM, setProcessingESM] = useState(false);
    const [ESMError, setESMError] = useState(null);
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };

    const handleExtractScenes = (event) => {
        event.preventDefault();
        if (!fileId) {
            return;
        }
        setProcessingES(true)

        const request_body = {
            file_key: fileId
        }
        fetch('https://8igwlkdufl.execute-api.us-west-2.amazonaws.com/v1/extract_scenes', {
            method: 'POST',
            headers: {
                'x-api-key': 'v8y7m7Fd3o9a7RsoC95RK93am9sA46Sl8MNauTpu',
                'Content-type': 'application/json'
            },
            body: JSON.stringify(request_body),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data?.error) {
                setESError(data.error)
            } else {
                setESError(null);
                const filteredScenes = data.scene_lines.map((scene, idx) => {
                    if (scene?.text?.includes('OMITTED')) {
                        return null;
                    }
                    return { ...scene,
                        lines: data.all_lines[idx]
                    };
                }).filter(Boolean);
                setSceneList(() => (filteredScenes));
                extractSceneMeta(filteredScenes);
            }
            setProcessingES(false);
        })
        .catch((error) => {
            console.error(error);
            setESError(error)
            setProcessingES(false);
        });
    };

    const extractSceneMeta = (scenes) => {
        if (!fileId) {
            return;
        }
        setProcessingESM(true)

        const request_body = {
            lines: scenes.map(scene => (scene.text))
        }

        fetch('https://8igwlkdufl.execute-api.us-west-2.amazonaws.com/v1/extract_scene_metadata', {
            method: 'POST',
            headers: {
                'x-api-key': 'v8y7m7Fd3o9a7RsoC95RK93am9sA46Sl8MNauTpu',
                'Content-type': 'application/json'
            },
            body: JSON.stringify(request_body),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data?.error) {
                setESMError(data.error)
            } else {
                setESMError(null);
                const sceneMeta = data.results.map((meta_list, idx) => {
                    const obj = {
                        text: scenes[idx].text,
                        page: scenes[idx].page,
                        lines: scenes[idx].lines
                    }
                    for (const meta of meta_list) {
                        if (meta.label === "scene_number") {
                            obj.scene_number = obj.scene_number ? obj.scene_number : meta.text;
                        }
                        else if (meta.label === "int_ext") {
                            obj.int_ext = obj.int_ext ? obj.int_ext + ', ' + meta.text : meta.text;
                        }
                        else if (meta.label === "location") {
                            obj.location = obj.location ? obj.location + ', ' + meta.text : meta.text;
                        }
                        else if (meta.label === "time") {
                            obj.time = obj.time ? obj.time + ', ' + meta.text : meta.text;
                        }
                    }
                    return obj;
                })
                setSceneList(sceneMeta);
            }
            setProcessingESM(false);
        })
        .catch((error) => {
            console.error(error);
            setESMError(error)
            setProcessingESM(false);
        });
    };
  
    const handleUpload = (event) => {
        event.preventDefault();

        if (!selectedFile) {
            return;
        }

        setUploading(true);
        fetch('https://8igwlkdufl.execute-api.us-west-2.amazonaws.com/v1/upload_script', {
            method: 'POST',
            headers: {
                'x-api-key': 'v8y7m7Fd3o9a7RsoC95RK93am9sA46Sl8MNauTpu',
                'Content-type': 'application/pdf'
            },
            body: selectedFile,
        })
        .then((response) => response.json())
        .then((data) => {
            if (data?.msg === "success") {
                setFileId(data?.file_id)
                setUploadError(null)
            } else {
                setFileId(null)
                setUploadError(data?.msg)
            }
            setUploading(false);
        })
        .catch((error) => {
            console.error(error);
            setUploadError(error)
            setUploading(false);
        });
    };

    const loadDummyData = () => {
        setSceneList(data);
        return;
    }
  
    return (
      <div className='app-body'>
        <h1>Untitled Workbook</h1>
        <button onClick={loadDummyData}>Load Dummy Data</button>
        <p>
            or upload script:
        </p>
        <form onSubmit={handleUpload}>
            <input type="file" onChange={handleFileChange} />
            <button type="submit">Upload</button>
            <p>Max upload size 4 MB</p>
        </form>
        {uploading && <p>Upload in progress...</p>}
        {uploadError && (
            <div>
                <p>Upload Error: {uploadError}</p>
            </div>
        )}
        {fileId && (
            <div>
                <p>File ID: {fileId}</p>
                <button onClick={handleExtractScenes}>Extract Scenes</button>
            </div>
        )}
        
        {processingES && <p>Extracting scene headings...</p>}
        {ESError && (
            <div>
                <p>Error: {ESError.msg}</p>
            </div>
        )}

        {processingESM && <p>Extracting scene metadata...</p>}
        {ESMError && (
            <div>
                <p>Error: {ESMError.msg}</p>
            </div>
        )}

        {sceneList && <SceneTable sceneList={sceneList}/>}
        
      </div>
    );
  };
  
  export default Home;