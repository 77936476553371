import cwp_logo from '../../assets/cwp_logo.svg'

const Header = () => {
    return (
        <div className="header">
            <img className="logo" src={cwp_logo} alt="Logo" />
        </div>
    )
    
}

export default Header